.matrix-header,.matrix-body {
    text-align: center;
    border: 1px  solid #ccc;
    width: 120px;
    height: 90px;
    background-color: white;
    color: #2e7d32;
    font-weight: bolder;

}
.matrix-header-top{
    border-bottom:2px solid #ccc;
}
.matrix-header-left
{
    border-left:2px solid #ccc;
}
.matrix-body{
    color: #333;
}

.matrix-col-0.active:hover{
    background-color: #1a4d66 !important;
    color: white !important;
}

.matrix-col-0.active:hover ~ td.active{
    background-color: #e2eaf3 !important;
}
.matrix-col-0.active:hover ~ td:last-child
{
    background-color: #1a4d66 !important;
    color: white !important;
}
.matrix-col-1.active:hover{
    background-color: #1a4d66 !important;
    color: white !important;

}
.cell{
    min-width: 10em !important;
    word-break: break-all !important;
}

.matrix-col-1.active:hover ~ td.active{
    background-color: #e2eaf3 !important;
}
.matrix-col-1.active:hover ~ td:last-child
{
    background-color: #1a4d66 !important;
    color: white !important;

}
.matrix-col-2.active:hover{
    background-color: #1a4d66 !important;
    color: white !important;

}

.matrix-col-2.active:hover ~ td.active{
    background-color: #e2eaf3 !important;
}
.matrix-col-2.active:hover ~ td:last-child
{
    background-color: #1a4d66 !important;
    color: white !important;

}
.matrix-col-3.active:hover{
    background-color: #1a4d66 !important;
    color: white !important;

}

.matrix-col-3.active:hover ~ td.active{
    background-color: #e2eaf3 !important;
}
.matrix-col-3.active:hover ~ td:last-child
{
    background-color: #1a4d66 !important;
    color: white !important;

}
.matrix-col-4.active:hover{
    background-color: #1a4d66 !important;
    color: white !important;

}

.matrix-col-4.active:hover ~ td.active{
    background-color: #e2eaf3 !important;
}
.matrix-col-4.active:hover ~ td:last-child
{
    background-color: #1a4d66 !important;
    color: white !important;

}
.matrix-col-5.active:hover{
    background-color: #1a4d66 !important;
    color: white !important;
}


.matrix-col-5.active:hover ~ td.active{
    background-color: #e2eaf3 !important;
}
.matrix-col-5.active:hover ~ td:last-child
{
    background-color: #1a4d66 !important;
    color: white !important;

}
.matrix-col-6.active:hover{
    background-color: #1a4d66 !important;
    color: white !important;

}

.matrix-col-6.active:hover ~ td.active{
    background-color: #e2eaf3 !important;
}
.matrix-col-6.active:hover ~ td:last-child
{
    background-color: #1a4d66 !important;
    color: white !important;

}
.high-light.active{
    background-color: #e2eaf3 !important;
}
.disabled{
    background-color: #e4e4e4 !important;
}
.active-header{
    background-color: #1a4d66 !important;
    color: white !important;
}

.rotate {
    transform: rotate(90deg) !important;
}
#selected{
    background-color: #1a4d66 !important;
    color: white !important;
}
.matrix-label-left{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 5rem;
    display: block;
    min-height: 90%;
    margin-top: 4rem !important;
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #ccc;
    color: #2e7d32;
    font-weight: bolder;

}
.matrix-label-top{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #fff;
    width: 80%;
    border: 1px solid #ccc;
    color: #2e7d32;
    padding: 0.7rem;
    font-weight: bolder;
}
.box-shadow{
    box-shadow: 5px 5px 5px 5px #333;

}
    
.matrix-label-text{
    display: inline-block;
    width: 40rem;
    top: 20rem !important;
    margin: 0;
    padding: 2rem 0 0;
    position: relative;
    left: -17.667rem;

    -webkit-transform: rotate(
90deg
);
    transform: rotate(
90deg
);
}
.matrix-button{
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align:center;
    background: #fff;
    border: 1px solid #ccc;
    border-right: none;
    color: black;
  }
  .matrix-button{
    box-shadow: 0px 1px 2px 1px #2e7d32 !important;
  }
  .matrix-button.btn-not-allowd{
    background-color:#eeeeee !important;
    color: black !important;
    outline: none !important;
    border: 1px solid #0000004a  !important;
    box-shadow: none !important;
  }