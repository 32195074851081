.search-filter-wrapper{
    padding: 1em 0em 0em 0em !important;  
    z-index: 2;
    border-radius: 5px;    
}
.search-filter-inside{
    -webkit-box-shadow: inset 0 0.91667rem 0.66667rem -0.83333rem #ccc, inset 0 -0.91667rem 0.66667rem -0.83333rem #ccc;
    box-shadow: inset 0 0.91667rem 0.66667rem -0.83333rem #ccc, inset 0 -0.91667rem 0.66667rem -0.83333rem #ccc;
}
.filter-rows{
    border-left: 1px solid #ccc;
}
.filter-rows:first-of-type{
    border-left: none
}
.filter-input-inditor{
    float: right;
    height : auto !important;
    min-height: 26px !important;
}
.filter-inut-indictor-text{
    font-weight: 800;
    margin-bottom: 0;
    font-size: 13px;
    align-items: center;
}
.filter-bottom-button{
    font-size: 12px !important;
    padding: 9px .75rem !important;
    cursor: pointer;
}
.filter-title{
    font-weight: 600;
    font-size: 16px;
}

.et-ibe-checkbox[type="checkbox"]:checked {
    background-image: none;
    background-color: #2e7d32;
  }
  
  .et-ibe-checkbox {
    opacity: 1 !important;
    z-index: 1 !important;
    position: relative;
    width: 1.35rem;
    height: 1.35rem;
    background-color: #fff;
    border: 0.125rem solid #2e7d32;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    -webkit-transition: background-color 0.15s ease-in-out,
      background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,
      background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    border-radius: 4px;
    cursor: pointer;
  }

  .et-ibe-checkbox[type="checkbox"]:checked[disabled] {
    background-image: none;
    background-color: #ddd;
  }
  .et-ibe-checkbox[disabled] {
    border: 0.125rem solid #c5c5c5;
  }

  .et-ibe-checkbox:focus {
    outline: none;
  }
  .et-ibe-checkbox[type="checkbox"]:after {
    content: "";
    position: absolute;
    width: 1.1rem;
    height: 1.1rem;
    z-index: 1;
    display: block;
    background-color: #fff;
    border-radius: 4px;
  }
  .et-ibe-checkbox[type="checkbox"]:checked:after {
    content: "";
    position: absolute;
    display: block;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 0.415rem;
    height: 0.8725rem;
    border: 0.125rem solid #fff;
    border-top: 0;
    border-left: 0;
    margin-left: 0.35rem;
    margin-top: -1px;
    background-color: transparent;
    border-radius: 0px;
  }
  .filter-list-container::before {
    content: " ";
    width: 0; 
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    z-index: 1;
    margin-top: -8px;
    position: absolute;
    left: 47%;
  }
  .filter-list-container::after {
    content: " ";
    width: 0; 
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #5d5d5d;
    margin-top: -9px;
    position: absolute;
    left: 47%;
  }
  .fareoption-container {
    border: 1px solid #ddd;
    min-width: 11.66667rem;
    border-left: none;
    z-index: 0;
  }
  .fareoption-container:first-of-type {
      border: 1px solid #ddd;
  }
  .horizontal_flex_container {
    overflow-x: auto;
    overflow-y: hidden;
    transform: translateZ(0);
    -webkit-transition: -webkit-transform cubic-bezier(0.86, 0, 0.07, 1);
    transition: -webkit-transform cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform cubic-bezier(0.86, 0, 0.07, 1),
      -webkit-transform cubic-bezier(0.86, 0, 0.07, 1);
      scroll-snap-type: x mandatory;
  }
  @media (min-width: 992px) {
    .fareoption-container {
      min-width: 14.2%;
    }
  }
  .back-alternate-shadow {
    -webkit-box-shadow: 11px 0 7px -9px rgba(0, 0, 0, 0.3);
    box-shadow: 11px 0 7px -9px rgba(0, 0, 0, 0.3);
  }
  .next-alternate-shadow {
    -webkit-box-shadow: -11px 0 8px -7px rgba(0, 0, 0, 0.3);
    box-shadow: -11px 0 8px -7px rgba(0, 0, 0, 0.3);
  }
  
  .temp-custom-container{
      background: #ddd;
  }
  .filter-options{
    padding: 3px 9px;
    background-color: #ddd;
    border-radius: 14px;
    cursor: pointer;
  }
  .filter-option-list > button.clear-filter-button{
    margin-left: auto;
  }
  .filter-option-list > span{
    white-space: nowrap;
     overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
  }
  .clear-filter-button{
    font-size: 9px;
      border: 1px solid #b9b9b9;
      background: none;
      border-radius: 50%;
      margin-left: 8px;
      max-width: 18px;
  }
  .clear-filter-button:hover{
    background-color: #b6b6b6;
    color: white;
  }
  .filter-toggler-multi-criteria{
    padding: 3px 9px;
    background-color: #ddd;
    border-radius: 14px;
    cursor: pointer;
  }

  .filter-option-list{
    padding: 3px 9px;
    cursor: pointer;
    display: inline-flex;
    overflow: hidden;
  }
  .filter-option-list:hover{
    background-color: #dddddd6b;
  }
  .sort-by-button{
    background-color: white;
    border : none;
  }
  .sort-by-button:focus{
    outline: none !important;
    box-shadow : none
  }
  .filter-list-container{
    border: 1px solid #00000054;
    border-radius: 6px;
    position: absolute !important;
    box-shadow: 0 0px 8px 0px #00000036;
    background-color: white;
    z-index: 1;
    margin-top: 11px;
    left: 15px;
  }
  .clear-filter-button-for-each{
    background-color: white;
    border: none;
    float: right;
    color: #2e7d32;
    font-weight: 600;
    font-size: 16px;
  }
  .clear-filter-button-for-each[disabled]{
    color: #ddd;
  }
  .filter-highlight{
    font-weight: 600;
    float: right;
  }
  .clear-all-filterCriteria{
    background-color: white;
    border: none;
    font-size: 18px;
    font-weight: 500;
    color: #2e7d32;
    margin-bottom: 8px;
  }
  .clear-all-filterCriteria[disabled]{
    color: #ddd;
  }
  .search-filter-wrapper{
    background-color: white;
  }
  .internatl-filter-option-container{
    width: 100%;
  }
  #display_on_flight_ribbon_out_of_view_port{
    color: #2e7d32;
    font-size: 18px;
    font-weight: 600;
    display: none;
}
#display_on_flight_ribbon_out_of_view_port.display_it{
  display: inline-block;
}
  @media (min-width: 576px) { 
    .filter-option-list > span{
      font-size: 16px;
    }
    .internatl-filter-option-container{
      border-bottom: 1px solid #ddd;
    }
   }

@media (min-width: 768px) { 
  .filter-list-container{
    left: 25%;
  }
 }
 @media (max-width: 768px){
  .internatl-filter-option-container{
    border-radius: 7px;
  }
 }

@media (min-width: 992px) { 
  .filter-list-container{
    left: 15%;
  }
 }

@media (min-width: 1200px) { 
  .filter-list-container{
    left: 10%;
  }
 }
  
