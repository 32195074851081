body {
  background: #a4adc6 !important;
  font-family: "Roboto", Arial, sans-serif !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}
.btn-ethiopian {
  background-color: #fff !important;
  color: #2e7d32 !important;
  border: 1px solid #2e7d32 !important;
}
.btn-ethiopian-danger {
  background-color: #fff !important;
  color: black !important;
  border: 1px solid #d32f2f !important;
}
.btn-not-allowd {
  cursor: not-allowed !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus:not(:disabled):not(.disabled),
.btn:focus,
.btn:active,
.btn:hover {
  box-shadow: none !important;
  outline: 0;
  background-color: #2e7d32 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
input:hover,
input:focus,
input:active,
input:active:focus:not(:disabled):not(.disabled),
input:focus,
input:active,
input:hover {
  box-shadow: none !important;
  outline: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.exception-msg {
  color: #d32f2f;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.close-exception-modal-btn {
  color: #d32f2f;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  background-color: #fff;
  border: 0;
  font-size: 18px;
}
.App-container {
  min-height: 75vh;
  padding-bottom: 4rem;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.is-invalid-select > div {
  border-color: rgb(147 0 0) !important;
}
.std-et-color {
  color: #2e7d32 !important;
}

.std-et-bg-color {
  background-color: #2e7d32 !important;
}

@media screen and (max-width: 768px) {
  background-color: #a4adc6 !important;
}
