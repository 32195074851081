.passenger-minus,.passenger-plus
{
    border: 2px solid #ddd !important;
    border-radius: 50px !important;
    height: 40px !important;
    width: 40px !important;
    position: relative;
    text-align: center;
    font-size: 24px !important;
    cursor: pointer !important;
    background-color: #fff !important;
    color: #000 !important;
    padding: 0 !important;
    font-family: Roboto, sans-serif;

}

.passenger-num-label
{
    font-size: 18px;
    font-weight: 500;
    color: transparent;
    text-shadow: 0 0 0 #000;
    font-family: Roboto, sans-serif;

}
.passenger-type-label
{
    font-size: 18px;
    font-weight: 500;
    color: transparent;
    text-shadow: 0 0 0 #000;
    font-family: Roboto, sans-serif;
}
.passenger-selector-input{
    color: transparent !important;
    text-shadow: 0 0 0 #000;
    cursor: pointer !important;
    font-family: Roboto, sans-serif;
    font-size: 18px !important;

}
.passenger-btn-disabled
{
    cursor: not-allowed !important;
    color: #ddd !important;
}
.passenger-type-selector-list{
    position: absolute;
    left: 15px;
    right: 15px;
    top: 2.91667rem;
    z-index: 1000;
}
.passenger-caret
{
    position: absolute;
    right: 35px;
    top: 1rem;
    font-size: 25px;
    color: #cccccc;
    cursor: pointer;
    z-index: 0;
}