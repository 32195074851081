.calendar-date {
  height: 5em;
  margin: 0.4em;
  border: 1px solid #ddd;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 142px;
  font-size: 1.4em;
}
.calendar-date-container {
  width: 14.28%;
}
.calendar-date.present:hover {
  border: 1px solid #5d5d5d;
  opacity: 0.8;
}
.fare-calendar-price {
  font-size: 17px;
  font-weight: 600;
}
.fare-calendar-no-flight {
  font-size: 17px;
}
.fare-calendar-currency {
  font-size: 17px;
  font-weight: 500;
}
.calendar-dateHeader-container {
  width: 14.28%;
  border-radius: 6px;
  border: 1px solid white;
}

.calendar-dateHeader {
  height: 5em;
  background: white;
  cursor: pointer;
  width: 100%;
}
.calender-weekheader-container {
  position: sticky;
  top: 0;
  box-shadow: 0px 4px 3px 1px #00000057;
  margin-bottom: 1em;
  padding: 0;
  background-color: white;
  z-index: 2;
  border-radius: 5px;
}
.no-flight-day {
  background-color: #ddd;
}
.calender-month-select {
  min-height: 38px !important;
  max-width: 181px;
  text-align-last: right;
  padding-right: 56px;
}
.fare-calender-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 12;
  height: 5em;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px -2px 8px 0px #6363638a;
}
div.calendar-date-container.active > div.calendar-date {
  border: 2px solid #2e7d32;
  color: #2e7d32;
  font-weight: 600;
}
p.selected-departure-date-p {
  font-size: 19px;
}
.selected-departure-date {
  color: #2e7d32;
  font-weight: 600;
}
.clear-selected-date {
  position: absolute;
  color: #690000;
  margin-left: 8%;
  margin-top: 19px;
  border: 1px solid #690000;
  border-radius: 50%;
  padding: 0px 8px 2px;
  z-index: 1;
  cursor: pointer;
  font-size: 14px;
}
.search-parameters {
  font-size: 17px;
  padding: 1em 1em;
  font-weight: 600;
}
.clear-selected-date-on-bottom {
  position: absolute;
  color: #690000;
  margin-left: 2%;
  margin-top: -2px;
  border: 1px solid #690000;
  border-radius: 50%;
  padding: 0px 8px 2px;
  z-index: 1;
  cursor: pointer;
  font-size: 14px;
}
.fare-calendar-sold-out{
    display: flex !important;
    text-align: center;
  }
@media (max-width: 991px) {
  .calender-weekheader-container,
  .fare-calendar-months-container {
    max-width: 90% !important;
  }
}
@media (max-width: 768px) {
  .calendar-date {
    margin: 0;
    border-radius: 0px;
    height: 4em;
  }
  .calendar-date.calendar-date-left-end{
    border-radius: 5px 0px 0px 5px;
  }
  .calendar-date.calendar-date-right-end{
    border-radius: 0px 5px 5px 0px;
  }
  .fare-calendar-price {
    display: flex !important;
    font-size: 14px;
    font-weight: 700;
  }
  .fare-calendar-total-price-container {
    display: flex !important;
    flex-flow: column;
  }
  .fare-calender-day {
    font-size: 17px;
  }
  .fare-calendar-no-flight {
    font-size: 13px;
    display: flex !important;
  }
  .fare-calendar-currency {
    font-size: 12px;
    display: flex !important;
    justify-content: center;
  }
  .fare-calendar-months-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .clear-selected-date {
    display: none;
  }
  .calendar-date-container {
    margin-bottom: 3px;
  }
  .fare-calender-footer {
    height: 7em;
  }
  .fare-calendar-sold-out{
    font-size: 13px;
  }
}
@media (min-width: 992px) {
  .clear-selected-date {
    margin-left: 6%;
  }
}
@media (min-width: 1480px) {
  .clear-selected-date {
    margin-left: 6%;
  }
}
/* 1280 */
/* div.calendar-date-container.active::after {
  content: "x";
  position: absolute;
  color: #690000;
  margin-left: 8%;
  margin-top: 19px;
  border: 1px solid #690000;
  border-radius: 50%;
  padding: 0px 8px 2px;
  z-index: 1;
  cursor: pointer;
  font-size: 14px;
} */
