.itinerary-part-offer-header-label {
  font-size: 16px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
}
.flight-selection-container {
  width: 96%;
  margin: auto;
  max-width: 1400px;
}
.flight-selection-head-txt {
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.brand-label {
  font-size: 20px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.itinerary-part-offer-header {
  padding: 11px 15px;
  background-color: #fff;
}
.flight-time-label,
.flight-duration-label {
  font-size: 16px;
  font-weight: 500;
}
.itinerary-time-duration-row.row {
  border-bottom: 1px solid #ddd;
  padding: 5px 5px;
  margin-top: 5px;
  border-radius: 5px;
}
.itinerary-part-offer {
  padding: 10px 15px;
  background-color: #fff;
  display: block;
  border-radius: 5px;
}
.itinerary-brand-header {
  font-size: 20px;
  font-weight: 500;
  /* padding: 20px 10px; */
  /* border: 1px solid #ddd; */
  margin-top: 10px;
  font-family: Roboto, sans-serif;
  color: #2e7d32;
}
.itinerary-brand-item-container {
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  font-family: Roboto, sans-serif;
}

.itinerary-brand-item.first {
  border: 0;
  padding-left: 35%;
  font-size: 18px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.itinerary-brand-item {
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #333;
}
.itinerary-brand-item.total-price {
  padding: 10px 30px;
  font-size: 25px;
  font-family: Roboto, sans-serif;
}
button.brand-selector-btn {
  background: white;
  border: 1px solid #2e7d32;
  color: #2e7d32;
  padding: 10px 25px;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
}
button.brand-selector-btn:hover {
  background-color: #2e7d32;
  color: #fff;
  border: 1px solid #fff;
}
.itinerary-brand-container {
  box-shadow: 0 5px 10px #ddd;
}
.flight-time {
  font-size: 20px;
  font-weight: 600;
  font-family: Robot, sans-serif;
}
.flight-duration,
.flight-route {
  font-size: 18px;
  font-weight: 500;
  font-family: Robot, sans-serif;
}
.flight-route {
  font-size: 16px;
  font-weight: 400;
  font-family: Robot, sans-serif;
}
.itinerary-part-offer-first {
  margin-top: 0 !important;
}
.flights-operated-by {
  font-size: 18px;
  font-weight: 500;
  font-family: Robot, sans-serif;
}
.brand-price-selector {
  background: #d32f2f !important;
  border: #d32f2f !important;
  font-size: 16px;
  font-weight: 500;
  font-family: Robot, sans-serif;
}
.flight-separtor-dot {
  position: relative;
  top: -6px;
  left: -3px;
  border-top: 1px solid #adb5bd;
  width: 100% !important;
  display: inline-block;
  margin-left: -1px;
}
.flight-time-mob {
  font-size: 14px;
  font-weight: 500;
  font-family: Robot, sans-serif;
}
.fly-price-info-mob {
  border-top: 1px solid #ddd;
  font-size: 16px;
  font-weight: 500;
  font-family: Robot, sans-serif;
}
.itinerary-part-offer-mob.bg-white {
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  cursor: pointer;
  display: none;
}
.operating-flight-mob {
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  font-weight: 500;
  font-family: Robot, sans-serif;
}
.flight-city-duration-mob {
  font-size: 16px;
  font-weight: 500;
  font-family: Robot, sans-serif;
}
.fly-price-mob {
  padding: 12px;
  color: #2e7d32;
  font-size: 18px;
}
.itinerary-brand-col {
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  margin-bottom: 0px;
}
.itinerary-brand-item-head {
  color: #28a745;
  font-weight: 600;
  font-family: Robot, sans-serif;
}
.itinerary-brand-mob {
  font-weight: 500;
  font-family: Robot, sans-serif;
  padding: 0;
}
.fare-option-head {
  font-size: 18px;
  font-weight: 500;
}
.fly-brand-info {
  font-size: 14px;
  border-top: 1px solid #ddd;
}
.fly-brand-item-info-mob {
  font-size: 14px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  background-color: #fff;
  margin: 20px 15px;
  border-radius: 10px;
  border: 1px solid #e9ecef;
}
.brand-name,
.brand-price {
  color: #2e7d32;
  font-size: 16px;
  font-weight: 700;
}
.brand-fare-breakdown {
  background-color: #e9ecef;
  margin: 0 !important;
  font-weight: 500;
  font-family: Robot, sans-serif;
}
.brand-fare-breakdown-col.col {
  border-left: 2px solid #fff;
  padding: 20px 0;
}
.brand-type.active {
  border-bottom: 2px solid #2e7d32 !important;
}
.brand-type {
  padding: 10px 0;
}
.fare-option-close {
  font-size: 1.7rem;
  position: relative;
  top: -19px;
  cursor: pointer;
  left: 13px;
  color: #bf7878;
  background: white;
  /* border-radius: 100%; */
  padding: 0;
  border: 0;
}
.selected-flight-review-txt {
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  color: #fff;
}
.selected-itinerary-part-offer-date {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
}
.selected-itinerary-part-offer-head-info.row {
  padding: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
.selected-itinerary-part-offer-info {
  padding: 18px;
  padding-bottom: 10px;
  margin: 0;
  font-size: 1.1rem;
}
.selected-flight-detail-btn {
  background-color: #fff;
  color: #2e7d32;
  border: 1px solid #2e7d32;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
}
.change-selected-fly-btn {
  background-color: #fff;
  color: #2e7d32;
  border: 1px solid #fff;
  padding: 12px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  border: 1px solid #ddd !important;
}
.segment-info-date {
  font-size: 1.2rem;
  line-height: 1;
  font-family: Oswald, sans-serif;
}
.segment-info-airportcode {
  font-size: 1.2rem;
  line-height: 1;
  font-family: Oswald, sans-serif;
  font-weight: 500;
}
.flight-selection-summary {
  font-family: Roboto, sans-serif;
  font-weight: 500;
}
.segment-info-airport-name,
.segment-info {
  font-family: Oswald, sans-serif;
}
.segment-info-col {
  border-left: 1px solid #2e7d32;
  padding-left: 20px;
  margin-left: 25px;
}
.segment-line:before {
  background: #2e7d32;
  content: "";
  margin-right: 1rem;
  position: absolute;
  left: -10px;
  width: 1.16667rem;
  height: 1.16667rem;
  content: "";
  border-radius: 100%;
  display: block;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.segment-info-destination {
  position: relative;
  top: 25px;
}
.segment-info-detail {
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}
.continue-btn {
  background: linear-gradient(150deg, #d32f2f 50%, #d32f2f 51%) !important;
  border: none;
  background-color: #d32f2f !important;
  background-size: 200% 200%;
  background-position: 30% 0;
  color: #fff;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 2px 22px !important;
  text-transform: uppercase !important;
  text-align: center;
  height: 45px !important;
  font-weight: 600;
  cursor: pointer !important;
}
.back-btn {
  border: 0;
  background: #ffffff !important;
  border-radius: 0.25rem;
  /* margin-top: 20px !important; */
  padding: 2px 22px !important;
  height: 45px !important;
  color: #777171;
  cursor: pointer !important;
}
.itinerary-part-offer-mob div {
  border-radius: 6px;
}
.selected-itinerary-part-mark-icon {
  color: #2e7d32;
  font-size: 32px;
}
.aircraft-info {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}
@media (max-width: 1400px) {
  .flight-selection-container {
    width: 90% !important;
    margin: auto;
    max-width: 1400px;
  }
}
@media (max-width: 768px) {
  .sm-text-center {
    text-align: center;
  }
  .flight-selection-container {
    width: 94% !important;
    margin: auto;
    max-width: 1400px;
  }
  .itinerary-part-offer,
  .itinerary-part-offer-brands-header,
  .itinerary-time-duration-row {
    display: none !important;
  }
  .itinerary-part-offer-mob {
    display: block !important;
  }
  .itinerary-part-offer-header-label {
    font-size: 14px;
  }
  .selected-itinerary-part-offer-date {
    font-size: 1.4rem;
  }
}
