.booking-type {
  background-color: #fff;
  width: 80%;
  border-radius: 7px 7px 0 0;
}

.booking-widget {
  /* margin: 29px 8px;
    border: 1px solid #ddd; */
  padding: 2rem 0rem;
  font-family: Roboto, sans-serif;
  width: 50%;
  /* border: 1px solid; */
  padding: 51px !important;
  float: left;
  margin-left: 15% !important;
  border-radius: 7px;
  margin-top: 3%;
}
.booking-widget-body {
  background-color: #d5dae6 !important;
  padding: 2em;
  border-radius: 0px 6px 6px 6px;
}
.search-form-header {
  line-height: normal;
  color: #000;
  font-weight: 500;
  font-size: 1.5rem;
  font-family: Roboto, sans-serif;
}

.search-form-remark .title-content {
  font-size: 16px;
}

.widget-input,
.react-tel-input,
.form-control {
  border: 1px solid #ced4da !important;
  min-height: 55px !important;
  font-family: Roboto, sans-serif !important;
  width: 100% !important;
}

.widget-input:hover {
  border-color: #2e7d32 !important;
}

.journey-type {
  margin-bottom: 1rem;
  font-family: Roboto, sans-serif;
}

.journey-type button,
.fare-option button,
.booking-type button {
  border: 1px solid #ced4da !important;
  font-size: 1rem;
  font-weight: 600;
  padding: 10px;
  font-family: Roboto, sans-serif;
  border: 1px solid #949494 !important;
}

.journey-type button {
  background-color: white;
}

.journey-type button.active {
  background-color: #2e7d32 !important;
}

.fare-option-label {
  font-weight: 500;
  font-family: Roboto, sans-serif;
}

.bk-submit-btn {
  font-size: 1rem !important;
  font-weight: 600 !important;
  min-height: 45px !important;
  font-family: Roboto, sans-serif;
}

.alert-custom-radius {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.notification-icon-container {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.heading-2 {
  font-size: 2rem;
}
#promo_code_button:hover > .promo-code-icon {
  color: white;
}
div.booking-type-container.btn-group > .btn-group:not(:last-child) > .btn,
div.booking-type-container.btn-group
  > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
}
div.booking-type-container.btn-group > .btn-group:not(:first-child) > .btn,
div.booking-type-container.btn-group > .btn:not(:first-child) {
  border-bottom-right-radius: 0;
}

@media (min-width: 1350px) {
  .booking-widget {
    width: 55% !important;
    margin: 0% 10% !important;
    padding: 3% 2% !important;
    max-width: 1000px !important;
  }

  .journey-type button,
  .fare-option button {
    font-size: 14px !important;
    padding: 10px;
  }
}

@media (max-width: 1350px) {
  .booking-widget {
    width: 75% !important;
    margin: 0% 1% !important;
    padding: 5% !important;
  }

  .journey-type button,
  .fare-option button {
    font-size: 14px !important;
  }
}

@media (max-width: 1047px) {
  .booking-widget {
    width: 98% !important;
    margin: 0 1% !important;
    padding: 5% !important;
  }

  .journey-type button,
  .fare-option button {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .search-form-remark .title-content {
    font-size: 14px;
  }
  .booking-type {
    width: 100%;
  }
  .booking-widget-body {
    padding: 1em;
    border-radius: 0px 0px 6px 6px;
  }
  .booking-widget {
    padding: 1em !important;
  }
  .alert-custom-radius {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .notification-icon-container {
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0px;
  }
}

.promo-code-btn {
  background-color: white !important;
  color: black !important;
  float: left;
  border: 1px solid #2e7d32 !important;
}

.promo-code-icon {
  color: #2e7d32;
  transform: rotate(90deg);
}
.promo-code-icon:focus {
  color: #fff !important;
}
.form-control:focus {
  border-color: #2e7d32 !important;
  box-shadow: none !important;
  min-height: "50px" !important;
}

#currecy-btn.fare-option-btn-active {
  color: #2e7d32;
  border: 1px solid #2e7d32 !important;
  font-weight: 800;
}

#miles-btn.fare-option-btn-active {
  color: #2e7d32;
  border: 1px solid #2e7d32 !important;
  font-weight: 800;
}

.success-alert-text {
  color: #2e7d32;
}
.et-checkbox-rounded input:checked~.checkmark {
  background-color: #2e7d32;
}
.et-checkbox-rounded .checkmark {
  position: absolute;
  top: 0;
  left: 5px;
  height: 25px;
  width: 25px;
  border: 1px solid #2e7d32;
  background-color: #fff;
  border-radius: 50%;
}

.et-checkbox-rounded .checkmark {
  height: 20px;
  width: 20px;
}

  .et-checkbox-rounded .checkmark::after {
      left: 7px;
      top: 3px;
  }

input[type="file"]::file-selector-button {
  display: none;
}

.et-checkbox .checkmark {
  top: 2px;
  left: 7px;
  height: 18px;
  width: 18px;
}

  .et-checkbox .checkmark::after {
      left: 5px;
      top: 2px;
  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
.et-checkbox-rounded input:checked~.checkmark:after {
  display: block;
}
.et-checkbox-rounded .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}