/* .footer{
    background-color: #333!important;
    width: 100%;
    bottom: 0;
    height: 80px;
    position: absolute;
    margin-bottom: -80px;
}
.footer-text{
    color: white;
    font-size: 100%;
    font: inherit;
    text-align: left;
} */
.footer-container {
  position: relative !important;
  top: 0px;
  background-color: #333 !important;
  padding: 50px 0 !important;
  width: 100%;
  margin: 0 !important;
  clear: both;
  position: relative;
  height: 200px;
  margin-top: -200px;
}
.social-links-fontawsome {
  font-size: 22px;
  color: #d5dae6;
}
.social-links-container.fa-facebook:hover,
.social-links-container:hover > .fa-facebook-f {
  color: #3b5999;
  border-color: #3b5999;
}

.social-links-container.fa-twitter:hover,
.social-links-container:hover > .fa-twitter {
  color: #0084ff;
  border-color: #0084ff;
}
.social-links-container.fa-linkedin:hover,
.social-links-container:hover > .fa-linkedin-in {
  color: #0077b5;
  border-color: #0077b5;
}
.social-links-container.fa-youtube:hover,
.social-links-container:hover > .fa-youtube {
  color: #cd201f;
  border-color: #cd201f;
}
.social-links-container.fa-instagram:hover,
.social-links-container:hover > .fa-instagram {
  color: #e4405f;
  border-color: #e4405f;
}
.social-links-container.fa-weibo:hover,
.social-links-container:hover > .fa-weibo {
  color: #df2029;
  border-color: #df2029;
}
.social-links-container {
  display: inline-block;
  border: 1px solid #d5dae6;
  border-radius: 50%;
  margin: 3px 10px;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 8px;
  align-items: center;
  justify-content: center;
}
