.seat_next_btn{
    position: relative;
    bottom: 3.3em;
}
a.nav-item.nav-link{
    background-color: white;
    color: black;
    border: 2px solid;
    border-color: #e9ecef #e9ecef #dee2e6;
    max-width: 12em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1em;
    font-weight: 500;
    /* border-color:     color: #565656;; */
}
a.nav-item.nav-link:hover{
    color: #565656;
}
a.nav-item.nav-link.active {
    color: #2e7d32;
    border: 2px solid #2e7d32;
}
.seatmap-header-title{
    margin: 0px;
    font-size: 17px;
    font-weight: 500;
}
.seatmap-header{
    border-bottom: 1px solid #dee2e6;
    padding: 1.5em;
}
.flight-inline-massage{
    margin: 0px;
}
.seat-selection{
    background-color: white;
    border-radius: 5px;
    border-top-left-radius: 0px;
}
/* .seat-selection-container{
    
} */
.left-container{
    border-right: 1px solid #dee2e6;
    padding: 0px;
}
.right-container{
    padding: 0rem 0;
}
.passangers-title{
    padding: 1em;
    border-bottom: 1px solid #dee2e6;
}
.passangers-title > h5{
    margin: 0px;
    font-size: 1em;
}
.single-passange{
    padding: 1em;  
}
.single-passange[disabled]{
    cursor: not-allowed;
}
.single-passange[disabled] span{
    color: #716b6b;
}
.passanger-lists{
    padding: 1em;
    border-bottom: 1px solid #dee2e6;
}
.passanger-identifier{
    padding: .4em .56em;
    background-color: #2e7d32;
    border-radius: 50%;
    color: white;
    cursor: pointer;
}
.passanger-identifier-infant{
    padding: 0.4em .75em;
    background-color: #2e7d32a1;
    border-radius: 50%;
    color: white !important;
}
.passanger-name{
    margin-left: 9px;
    margin-right: auto;
}
.selected-seat-name{
    font-size: 16px;
    font-weight: 600;
    margin-left: auto;
    margin-right: 14px;
}
.clear-seat-selection{
    font-size: 9px;
    border: 1px solid #b9b9b9;
    background: none;
    border-radius: 50%;
    max-width: 18px;
    max-height: 18px;
}
.legend-container{
    margin-top: 1em;
    padding: 0 1em;
    border: 1px solid #dee2e6;
    border-right: none;
    position: sticky;
    position: -webkit-sticky;
    top: 65px;
}
.legend-title{
    padding: 1em 0;
}
ul.seats-list{
    list-style-type: none;
    padding: 0;
}
.seat-legend{
    cursor: pointer;
}
.seat-legend-description{
    margin-left: 1em;
    margin-right: auto;
}
.seat-legend-price{
    margin-left: auto;
}
.seat-legend-price > span.amount{
    font-weight: 500;
    padding-right: 6px;
}
.aircraft-name{
    font-size: 1.5em;
}
.seat-map-deck{
    margin-bottom: .5em;
}
.seat-map-instructions{
    padding: 1em;
}
*:focus{
    outline: none;
}
.cancel-seat-continue-modal{
    border: 1px solid #ddd;
}
.seat-container-legend {
    width: 62%;
}
.seat-frame-legend{
    border: 2px solid #5a5a5a;
    height: 2em;
    min-width: 2.9em;
    border-top: none;
    position: absolute;
    top: 10px;
    cursor: pointer;
}
.seat-legend{
    border: 2px solid #5a5a5a;
    border-radius: 13px 13px 0 0;
    height: 2.4em;
    min-width: 2.4em;
    cursor: pointer;
}
.premium-seat .seat-frame-legend, .premium-seat .seat-legend, .premium-seat .extra-leg-seat-legend::before{
    border-color: blue;
  }
  .premium-seat .seat-legend, .premium-seat .extra-leg-seat-legend::before{
    background: #0000ff5c;
  }
.extra-leg-seat-legend::before {
    content: "";
    border: 2px solid #5a5a5a;
    border-radius: 13px 13px 0 0;
    cursor: pointer;
    position: absolute;
    min-width: 1.6em;
    top: -7px;
    height: .6em;
    border-color: #5a5a5ae0;
    border-bottom: none;
}
.seat-legend[disabled] {
    font-size: 1.7em;
    height: 1.4em;
    min-width: 1.39em;
    background-color: #d6d6d6;
    color: #444444;
}
.legend-medium{
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #d6d6d6;
    padding: 10px;
    user-select: none;
}
