.breadcrumb-custom {
  list-style: none;
  overflow: hidden;
  /* padding-left: 16%  !important; */
}
.breadcrumb-custom li {
  float: left;
}
.breadcrumb-custom li a,
.breadcrumb-custom li > span {
  color: #666;
  text-decoration: none;
  padding: 10px 0 10px 65px;
  position: relative;
  display: block;
  float: left;
  max-height: 65px !important;
}
.breadcrumb-custom li a.breadcrumb-custom-home::after,
.breadcrumb-custom li > span.breadcrumb-custom-home::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #fff;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
a.breadcrumb-custom-current {
  color: #fff !important;
}
.breadcrumb-custom li a.breadcrumb-custom-current::after,
.breadcrumb-custom li > span.breadcrumb-custom-current::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #2e7d32;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.breadcrumb-custom li a.breadcrumb-custom-disabled::after,
.breadcrumb-custom li > span.breadcrumb-custom-disabled::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #eee;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
a.breadcrumb-custom-disabled,
a.breadcrumb-custom-current {
  height: 65px;
}

.breadcrumb-custom-home {
  background-color: #fff !important;
}
.breadcrumb-custom-current {
  background-color: #2e7d32;
}
.breadcrumb-custom-disabled {
  background-color: #eee;
}
.breadcrumb-custom li a::before,
.breadcrumb-custom li > span::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ccc;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 3px;
  left: 100%;
  z-index: 1;
}
.breadcrumb-Card {
  min-width: 100%;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgb(0 0 0 / 14%);
  border-radius: 0.25rem;
  max-height: 68px !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 10;
  display: block;
  box-shadow: 0 2px 4px rgb(0 0 0 / 41%);
}
.edit-flight-button {
  position: relative;
  top: -51px;
}
.previsous {
  color: #2e7d32 !important;
}
.bread-crumb-mobile-dropdown {
  background-color: white;
  padding: 11px 24px;
  border-radius: 3px;
  cursor: pointer;
  border-radius: 3px 3px 0 0;
  border-bottom: solid 1px #999;
  margin-top: 2px;
  position: sticky;
  top: 0px;
  z-index: 3;
  max-width: 70%;
}
.bread-crumb-mobile-dropdown:hover {
  border: 1px solid #2e7d32;
}
.bread-crumb-mobile-dropdown {
  border: 1px solid white;
}
.bread-crumb-mobile-active {
  background-color: #2e7d32;
}
.bread-crumb-mobile-dropdown-list {
  position: absolute;
  background-color: #eee;
  border-radius: 0 0 3px 3px;
  display: block;
  left: 0;
  right: 0;
  margin-top: 5px;
  z-index: 1000;
  width: 100% !important;
  box-shadow: 1px 2px 6px 0px #a0a0a0;
}
.bread-crumb-mobile-item {
  padding: 11px 24px;
  cursor: pointer;
}
.parent-div {
  display: none;
}
.bread-crumb-mobile-item-md {
  /* padding: 0px 10px; */
  border-top: 1px solid #ddd;
  margin: 0 5px;
}
.bread-crumb-mobile-dropdown {
  padding: 0;
  display: none;
}
@media (max-width: 1037px) {
  p.breadcrumb-custom-disabled.btn-not-allowd {
    padding: 15px;
    margin: 0;
  }
  .parent-div {
    display: block;
  }
  .bread-crumb-mobile-item {
    padding-left: 10%;
  }
  .breadcrumb-custom-current {
    padding: 15px;
    margin: 0;
    color: #fff;
  }
  .bread-crumb-mobile-dropdown {
    padding: 0;
    display: block;
  }
  .breadcrumb-Card {
    display: none;
  }
  .previsous {
    color: #2e7d32 !important;
    padding: 0 15px;
    margin: 0;
  }
  .bread-crumb-mobile-item-md {
    padding: 0 10%;
  }
}
.shopping-cart > div.accordion div.card {
  border: none;
}
.bread-crumb-mobile-dropdown-check-in {
  max-width: 80%;
}
@media (max-width: 768px) {
  .bread-crumb-mobile-item-md {
    padding: 0 2%;
  }
  .edit-flight-button {
    top: -79px;
  }
}
@media (max-width: 1160px) {
  .breadcrumb-custom li a,
  .breadcrumb-custom li > span {
    padding-left: 50px !important;
  }
}
.parent-div {
  min-width: 100%;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%), 0 0 40px rgb(0 0 0 / 10%) inset;
  border-radius: 4px;
  position: sticky;
  top: 2px;
  z-index: 3;
}
