.shopping-cart {
  width: 100%;
  background-color: whitesmoke;
  box-sizing: border-box;
}
.shopping-cart-header-border {
  border-color: hsl(0deg 0% 80%);
  border-style: solid;
  border-width: 1px 0px 0px 0px;
}
.shopping-cart-header {
  line-height: "3.5rem";
  font-size: "29px";
  font-weight: "500";
  font-family: "Lato, serif";
}
.shopping-cart-left-icon {
  font-family: Oswald, sans-serif;
  color: #2e7d32;
  position: relative;
  top: 0;
  float: left;
}
.shopping-cart-right-icon {
  font-family: Oswald, sans-serif;
  color: #2e7d32;
  position: relative;
  top: 0;
  float: right !important;
  line-height: 2.5rem;
}
.shopping-cart-center-icon {
  font-family: Oswald, sans-serif;
  font-weight: 400;
  line-height: 2.5rem;
  color: #2e7d32;
  justify-content: center;
}
.shopping-cart-title {
  font-family: Oswald, sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
  color: #2e7d32;
}
.shopping-cart-price {
  font-family: Oswald, sans-serif;
  font-weight: 600;
  display: inline-block;
  line-height: 1.5rem;
  color: #333;
}
.shopping-cart-disapled {
  color: #898989 !important;
}
.shopping-cart-date {
  font-weight: 500;
}
.shopping-cart-internal-header {
  background-color: #f2f2f2;
}
.shopping-cart-internal-title {
  color: #4a5767;
  display: flex;
  font-weight: 600;
}
.shopping-cart-airline-logo {
  max-width: 1.333rem !important;
  max-height: 1.333rem !important;
}

.shopping-cart-total-price {
  color: #2e7d32;
  font-size: 1.5rem;
  font-weight: 500;
}
.shopping-cart-label {
  display: block;
  font-size: 1.2rem;
  color: #898989;
}
.shopping-cart-label-tag {
  cursor: pointer;
  text-decoration: underline;
}
.shopping-cart-label-tag:hover {
  text-decoration: none;
  color: #2e7d32;
}
.shopping-cart-active {
  color: #2e7d32;
}
.shopping-cart-tab .nav-link.active {
  background-color: #2e7d32;
  color: white;
  border: 1px solid #a1a1a1;
  border-bottom-color: transparent;
  border-radius: 0.33333rem 0.33333rem 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 15;
}
.shopping-cart-tab a.nav-item.nav-link{
  color: black !important;
}
.baggage-info {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 3px 16px 0 #ddd;
  color: #333;
  cursor: pointer;
  padding-right: 4.25rem;
  position: relative;
  transition: color 0.25s cubic-bezier(0.86, 0, 0.07, 1),
    border 0.25s cubic-bezier(0.86, 0, 0.07, 1),
    border-radius 0.25s cubic-bezier(0.86, 0, 0.07, 1);
  width: 100%;
  z-index: 10;
}
.baggage-info-title {
  font-family: Lato, serif !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #333;
}
pre {
  white-space: pre-wrap;
}
.loading {
  color: #2e7d32;
  font: 300 4em/150% Impact;
  text-align: center;
}

/* loading dots */

.loading:after {
  content: "  .";
  animation: dots 1s steps(5, end) infinite;
  align-self: center;
  justify-content: center;
  display: flex;
}
a.nav-item.nav-link.active {
  color: white;
}
.btn-shopping-cart-mobile {
  margin-top: -108px;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #2e7d32;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #2e7d32, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #2e7d32, 0.5em 0 0 #2e7d32;
  }
}

.shopping-cart-seat-header {
  padding: 0.4em 1em;
  background-color: #f1f1f1;
  color: #515151;
}
.shopping-cart-selected-seat-name {
  margin-left: 9px;
  margin-right: auto;
  color: #5a5858;
}
.shopping-cart-selected-seat-price {
  font-size: 16px;
  font-weight: 600;
  margin-left: auto;
  margin-right: 14px;
}
.passanger-name-shopping-cart {
  margin-left: 9px;
  margin-right: auto;
  color: #5a5858;
}
.shopping-cart-selected-total-seat-price {
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
  color: black;
}
.shopping-cart-seat-section {
  max-height: 20em;
  overflow-y: auto;
}
.passanger-identifier-shopping {
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  color: #5a5858;
  cursor: pointer;
  border: 1px solid #5a5858;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -ms-flex-align: center;
}
.passanger-identifier-infant-shopping {
  padding: 0.4em 0.75em;
  border-radius: 50%;
  color: #5a5858;
  cursor: pointer;
  border: 1px solid #5a5858;
  cursor: pointer;
}
/* width */
.shopping-cart-seat-section::-webkit-scrollbar {
  width: 7px;
}
/* Track */
.shopping-cart-seat-section::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.shopping-cart-seat-section::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
/* Handle on hover */
.shopping-cart-seat-section::-webkit-scrollbar-thumb:hover {
  background: #555;
}
div.seat-column-9 .seat-row {
  min-width: 25em;
}
.active-shopping-cart-header {
  z-index: 1;
  border-bottom: 1px solid #54545499;
  box-shadow: 0px 2px 10px 0px #545454d1;
  cursor: pointer;
}
.shopping-cart-collapse {
  box-shadow: rgb(204, 204, 204) 0px 0.91667rem 0.66667rem -0.83333rem inset,
    rgb(204, 204, 204) 0px -0.91667rem 0.66667rem -0.83333rem inset;
}

@media (min-width: 1200px) {
  .shopping-cart {
    min-width: 369px;
  }
}
