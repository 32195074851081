.exchange-container {
  padding: 2rem;
}
.exchange-container .city-name {
  font-size: 1.4rem;
  display: inline-block;
}
.confirm-badge {
  display: inline-block;
  width: 100px;
  text-align: center;
  background: #2e7d32;
  color: #fff;
  border-radius: 50px;
  font-size: 1rem;
  padding: 4px 0;
  float: right;
}
.exchange-container .custom-img-sm-flag {
  position: relative;
  top: -3px;
  width: 24px;
}
.exchange-container .flight-info-container {
  border: 1px solid #ccc;
  padding: 1rem;
  box-shadow: 0 0px 10px #ddd;
  margin-bottom: 2rem;
}
.flight-info-container .flight-detail {
  font-size: 1.1rem;
}
.exchange-cancel-btn {
  background: linear-gradient(150deg, #fff 50%, #fff 51%) !important;
  border: 1px solid #55555542 !important;
  background-size: 200% 200%;
  background-position: 30% 0;
  color: #000000 !important;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 2px 22px !important;
  text-transform: uppercase !important;
  text-align: center;
  height: 45px !important;
  font-weight: 600;
  cursor: pointer !important;
}
.exchange-cancel-btn:hover {
  color: #000000 !important;
  padding: 2rem;
}
.exchange-widget-container {
  padding: 2rem;
}
.passenger-counter {
  background: green;
  text-align: center;
  display: inline-block;
  width: 2.3rem;
  height: 2.3rem;
  border: 1px solid #ccc;
  border-radius: 100%;
  padding-top: 5px;
  color: #fff;
  margin-right: 1rem;
}
.exchange-container .selected-flight-detail-btn {
  font-size: 1.1rem;
}
.exchange-payment-summary-container {
  width: 96%;
  margin: 2% 2%;
}

.continue-btn:disabled {
  cursor: not-allowed !important;
}
.exchange-flight-selection-header {
  padding: 2rem;
  display: flex;
}
.exchange-flight-selection-header p {
  font-size: 1.1rem;
}
.exchange-flight-selection-header h5 {
  font-weight: 600;
}
.exchange-waring-icon-container {
  height: 65px;
  background-color: #f9b646;
  width: 65px;
  margin-right: 25px;
  border-radius: 100%;
  padding: 26px;
}
span.exchange-waring-icon {
  color: white;
  font-size: 40px;
  position: relative;
  top: -25px;
  left: -15px;
}
.exchange-flight-selection-header .originalPayment {
  color: #2e7d32;
  font-weight: 600;
}
.exchange-payment-summary-actions {
  display: flex;
  flex-direction: row;
}
.exchange-payment-summary-actions .add-to-calendar,
.exchange-payment-summary-actions .print-icon,
.exchange-payment-summary-actions .pdf-icon {
  color: #999999;
  cursor: pointer;
  font-size: 1.1rem;
  box-shadow: 0 0 5px #dddddd85;
  margin-right: 10px;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #ccc;
}
.add-to-calendar-link {
  box-shadow: 0 5px 10px #ddd;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ddd;
  display: block;
  margin: 12px 0;
}
.add-to-calendar-btn {
  box-shadow: 0 5px 10px #ddd;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ddd;
  display: block;
  text-align: left;
  padding-left: 4rem;
}
@media (min-width: 1024px) {
  .exchange-payment-summary-container {
    width: 90%;
    margin: 2% 5%;
  }
}
@media (max-width: 768px) {
  .exchange-container .departure-icon {
    display: block;
  }
  .exchange-flight-selection-header {
    flex-direction: column;
  }
}
