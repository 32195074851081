.bag-card{
    max-width:400px !important; 
  }
  .bag-img{
      width: 100% !important;
  }
  /* .bag-intro{
  } */
  .bag-content{
    text-align: center;
      background-color: #fff;
      padding: .833rem .8333rem 0;
      border: .0833rem solid #ccc;
      border-bottom: none;
  }
  .bag-axillary{
    font-size: 0.9rem;
    color: #767676;
    text-align:center;
  }
  .bag-text{
        font-weight: 400;
      font-size: 1.333rem;
      margin-top: .833rem;
      margin-bottom: .833rem;
      color: #767676;
  }
  .bag-footer{
    text-align:center;
    
  }
    .bag-button,.bag-quantity-input {
      display: inline-block;
      width: 40px;
      height: 40px;
      padding :0;
      margin: 10px;
      text-align:center;
      background: #fff;
      border: 1px solid #ccc;
      border-right: none;
      color: black;
    }
    .bag-button{
      box-shadow: 0px 1px 2px 1px #2e7d32 !important;
    }
    .bag-button.btn-not-allowd{
      background-color:#eeeeee !important;
      color: black !important;
      outline: none !important;
      border: 1px solid #0000004a  !important;
      box-shadow: none !important;
    }
    .quantity{
      background-color: #eeeeee;
    }
    .bag-header{
      background-color: white !important;
      color: #4a5767;
    }
    button[type="button"]
{
  border:none;
}
button[type="button"]:focus
{
  
outline: none;}
.added-to-cart{
  color: #2E7D32;
}



    