.manage-booking-summary-itinerary span {
  color: #206080;
  cursor: pointer !important;
  font-weight: 600;
  padding: 10px;
  white-space: pre-line;
}
.manage-booking-summary-itinerary span:hover {
  background-color: #ddd;
}
.manage-booking-summary-actions {
  display: flex;
  flex-direction: row;
}
.manage-booking-summary-actions .add-to-calendar,
.manage-booking-summary-actions .print-icon,
.manage-booking-summary-actions .pdf-icon {
  color: #999999;
  cursor: pointer;
  font-size: 1.1rem;
  box-shadow: 0 0 5px #dddddd85;
  margin-right: 10px;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #ccc;
}
.add-to-calendar-link {
  box-shadow: 0 5px 10px #ddd;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ddd;
  display: block;
  margin: 12px 0;
}
.add-to-calendar-btn {
  box-shadow: 0 5px 10px #ddd;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ddd;
  display: block;
  text-align: left;
  padding-left: 4rem;
}
.manage-booking-summary-print {
  background-color: #fff;
  max-width: 210mm;
  padding: 25mm;
  font-size: 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  margin: 2.666rem auto;
  padding-bottom: 50mm;
}
.manage-booking-summary-print .pnr-container {
  border: 1px solid #ccc;
  padding: 2rem;
  word-wrap: break-word;
}
.manage-booking-summary-print .pnr {
  border: 1px solid #ccc;
  margin-top: 1.2rem;
  padding: 0.5rem;
}
.manage-booking-summary-print .price-container {
  border: 1px solid #ccc;
  padding: 1rem;
}
.manage-booking-summary-print .price-tax {
  color: #2e7d32;
  font-style: italic;
}
.manage-booking-summary-print .departure-label {
  font-weight: 600;
}
.manage-booking-summary-print .duration-aircraft .cols {
  border-left: 1px solid #ddd;
}
.manage-booking-summary-print .flight-detail-row {
  background-color: #efefef;
  border: 1px solid #ccc;
  padding: 1.5rem;
}
.payment-detail .payment-label {
  color: #2e7d32;
}
.payment-detail .payment-label.total {
  font-weight: bold;
}
/* .passenger-detail {
} */
.manage-booking-summary-print .custom-img-sm {
  width: 18px;
  margin-right: 5px;
}
.calendar-container h5 {
  cursor: pointer;
}
.manage-booking-summary-itinerary {
  display: flex;
  flex-direction: row;
}
span.pax-counter {
  display: inline-block;
  width: 25px;
  background: #ccc;
  height: 25px;
  position: relative;
  text-align: center;
  border-radius: 100%;
  color: #000;
  padding-top: 2px;
  font-weight: 600;
}
.price-free-seat-indicator{
  color: green;
  font-weight: 600;
}
.price-seat-indicator{
  font-weight: 600;
}
.myb-shopping-cart-title{
  font-size: 18px;
}
@media print {
  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
}
@media (max-width: 768px) {
  .manage-booking-summary-itinerary {
    flex-direction: column;
  }
  .myb-shopping-cart-title{
    font-size: 15px;
  }
}
@media (min-width: 1024px) {
  .manage-booking-summary-container {
    width: 90%;
    margin: 2% 5%;
  }
}
