.all-passengers-container {
  background-color: white;
  border-radius: 4px;
}
.document-needed-status {
  padding: 0.2em 0.5em;
  border-radius: 10px;
  background-color: #bb0b1c;
  color: white;
  border: 1px solid #bf0718;
}
.check-in-passenger-name-identifier{
    border: 1px solid #b7b7b7;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    color: #5f5f5f;
}
.check-in-passenger-fullname{
    font-size: 17px;
    font-weight: 600;
}
.check-in-passenger-type{
    font-size: 13px;
    font-weight: 500;
    color: #525252;
    margin-left: 2px;
}
.check-in-passenger-detail-buton{
    cursor: pointer;
    color: #2E7D32 !important;
    font-size: 17px;
    text-transform: uppercase;
    FONT-WEIGHT: 600;
}
.check-in-passenger-name-identifier, .check-in-passenger-fullname{
    cursor: pointer;
}
.check-in-passenger-departure-name, .check-in-passenger-arrival-name{
    font-size: 15px;
}
.airport-code-container{
    font-size: 15px;
    font-weight: 600;
}
.checkin-passenger-detail-container{
    margin-bottom: 1.5em;
}
.checkin-passenger-detail-container-active{
    box-shadow: 0px 5px 4px 0px #afafafbd;
    border-bottom: 1px solid #b5b5b5;
}
.passenger-detail-sub-title{
    font-size: 16px;
    padding: .5em;
    font-weight: 600;
}
.checkin-passenger-separator{
    border-top :  1px solid rgb(0 0 0 / 15%)
}
.document-ready-status{
        padding: 0.2em 0.5em;
        border-radius: 10px;
        background-color: #2E7D32;
        color: white;
        border: 1px solid #2E7D32;
}
.invalid-select-customized-placeholder{
    font-size: 11px;
}