.payment-form label.form-label,
.travel-bank-form label {
  color: #666666;
  font-size: 1rem;
  font-family: Roboto, sans-serif;
}
.travel-bank-form {
  font-family: Oswald, sans-serif;
}
.payment-option-logo {
  height: 3.467rem;
  opacity: 1;
}
.flocash-payment-option-col {
  background-color: #fff;
  padding: 15px 10px;
  cursor: pointer !important;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 5px 10px #ddd;
  font-family: Roboto, sans-serif;
  border: 1px solid #ddd;
}
.payment-option-col {
  background-color: #ddd;
  border: 1px solid #ddd;
  padding: 15px 10px;
  cursor: pointer !important;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 5px;
}
.select-payment-option-head {
  font-family: Roboto, sans-serif;
}
.payment-option-col.active {
  background: #fff !important;
  border-bottom: 3px solid #2e7d32;
  box-shadow: 0 5px 10px #ddd;
}
.payment-option-bg {
  height: 60px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
}
.credit-card-logos {
  height: 70px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
}
.credit-card-header {
  font-family: Roboto, sans-serif;
}
.payment-form .invalid-input-field {
  border: 1px solid #d32f2f !important;
}
.payment-form .invalid-input-field::placeholder {
  color: #d32f2f !important;
}
.union-pay-container .invalid-input-field {
  border: 1px solid #d32f2f !important;
}
.union-pay-container .invalid-input-field::placeholder {
  color: #d32f2f !important;
}
.invalid-input-field .css-1wa3eu0-placeholder {
  color: #d32f2f !important;
}
.flocash-payment .invalid-input-field {
  border: 1px solid #d32f2f !important;
}
.flocash-payment .invalid-input-field::placeholder {
  color: #d32f2f !important;
}
.travel-bank-form .invalid-input-field {
  border: 1px solid #d32f2f !important;
}
.travel-bank-form .invalid-input-field::placeholder {
  color: #d32f2f !important;
}
.travel-bank-submit-btn,
.payment-submit-btn {
  background: linear-gradient(150deg, #d32f2f 50%, #d32f2f 51%) !important;
  border: none;
  background-color: #d32f2f !important;
  background-size: 200% 200%;
  background-position: 30% 0;
  color: #fff;
  border-radius: 4px;
  font-size: 12px !important;
  padding: 2px 22px !important;
  text-transform: uppercase !important;
  text-align: center;
  height: 45px !important;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  cursor: pointer !important;
}
.payment-submit-btn:disabled {
  cursor: not-allowed !important;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer !important;
}
.payment-submit-terms-condition {
  font-family: Roboto, sans-serif;
}
#agreedToTerms,
.agreedToTerms-label {
  cursor: pointer;
}
.union-pay-container {
  font-family: Roboto, sans-serif;
}
.hold_reservation_container {
  font-family: Roboto, sans-serif;
}
section.payment-summary-remark,
.payment-summary-header {
  background: white;
  padding: 12px;
  margin-top: 20px;
  font-family: Roboto, sans-serif;
  color: #2e7d32;
}
.payment-summary-header h2 {
  font-size: 1.5rem;
}
section.payment-summary-remark {
  color: #000;
}
.payment-summary-header .check-icon {
  font-size: 2rem;
  color: #2e7d32;
}
.payment-summary-confirmation-header,
.payment-summary-trip-detail {
  background: white;
  padding: 12px;
  margin-top: 20px;
  font-family: Roboto, sans-serif;
  color: #2e7d32;
}
.payment-summary-itinerary {
  font-size: 1.4rem;
}
.warining-check-icon {
  color: #cd8511;
}
.payment-summary-trip-detail {
  color: #4a5767;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

section.payment-summary-trip-detail-list {
  border-top: 2px solid #ddd;
}
.payment-summary-trip-detail-head {
  cursor: pointer !important;
}
.payment-summary-passenger-detail {
  font-family: Roboto, sans-serif;

  background-color: #fff;
  margin-top: 10px;
  padding: 0 15px;
  margin: 0;
}
.payment-summary-passenger-detail h2 {
  color: #4a5767;
  cursor: pointer !important;
}
.payment-summary-passenger-detail-title {
  color: #4a5767;
  cursor: pointer !important;
  padding-top: 10px;
}
.pym-passenger-detail-head,
.pym-summary-psg-seat-head {
  color: #4a5767;
}
.pym-passenger-detai-list-itm {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 10px 0;
}
.pym-passenger-detail-collapser {
  cursor: pointer !important;
}
.pym-passenger-count {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #2e7d32;
  text-align: center;
  border-radius: 23px;
  color: white;
  background: #2e7d32;
  box-sizing: content-box;
  padding: 1px;
}
.pym-summary-airport-name {
  color: #4a5767;
  font-weight: 600;
}
.pym-passenger-name {
  color: #4a5767;
  font-weight: 500;
}
.ticket-number {
  font-size: 20px;
}
.payment-summary-trip-detail-list .airport-name {
  font-size: 1.3rem;
}
.payment-summary-actions {
  display: flex;
  flex-direction: row;
}
.payment-summary-actions .add-to-calendar,
.payment-summary-actions .print-icon,
.payment-summary-actions .pdf-icon {
  cursor: pointer !important;
  color: #999999;
  font-size: 1.1rem;
  box-shadow: 0 0 5px #dddddd85;
  margin-right: 10px;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #ccc;
}
.add-to-calendar-link {
  box-shadow: 0 5px 10px #ddd;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ddd;
  display: block;
  margin: 12px 0;
}
.add-to-calendar-btn {
  box-shadow: 0 5px 10px #ddd;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ddd;
  display: block;
  text-align: left;
  padding-left: 4rem;
}
@media (max-width: 768px) {
  .select-payment-option-head > h3 {
    font-size: 1.4rem;
  }
  .select-payment-option-head,
  .payment-summary-header h2 {
    font-size: 1.2rem;
  }
  .payment-summary-trip-detail-head,
  .payment-summary-passenger-detail h2 {
    font-size: 1.2rem;
  }
  .payment-summary-itinerary {
    font-size: 1rem;
  }
  .payment-summary-itinerary.pt-3 span {
    display: block;
  }
}
.green-bg{
  background-color: #ffad00 !important;
  color: white !important;
  border-color: #ffad00 !important;
  margin: 2px;
  border-radius: 8px;
  width: fit-content !important;
}