.Loading-div {
  position: fixed;
  z-index: 1000 !important;
  background-color: rgb(13 14 14 / 41%) !important;
  opacity: 1 !important;
  right: 0 !important;
  left: 0 !important;
  height: 100%;
  top: 0;
  width: 100%;
  /* background-image: url("/assets/image/loading2.jpg"); */
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loadingText {
  color: red !important;
}
#progress-loader {
  color: #fff !important;
}
.loader {
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  margin: 2vw;
  background-image: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  animation: bounce 1.5s 0.5s linear infinite;
}
.yellow {
  background-color: yellow;
}

.red {
  background-color: red;
  animation-delay: 0.1s;
}

.blue {
  background-color: green;
  animation-delay: 0.2s;
}
@keyframes bounce {
  0%,
  50%,
  100% {
    transform: scale(1);
    filter: blur(0px);
  }
  25% {
    transform: scale(0.6);
    filter: blur(3px);
  }
  75% {
    filter: blur(3px);
    transform: scale(1.4);
  }
}
.divcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.displayMessage {
  font-size: 25px;
  font-weight: 800;
  color: white;
  text-shadow: 2px 2px 3px #000000;
}
.fareoption-container-disabled-hover > div:hover{
border:none !important;
box-shadow: none !important;
}