.passanger-info-card-header{
    background-color: white;
    border-bottom: 1px solid #ccc;
    opacity: 1 !important;
}
.passanger-info-card-body{
    background-color: #e0e3e2;
}
/* Creating a custom checkbox 
based on demand */ 
.geekmark { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
    border-radius: 50% ;
  } 
  
  /* Specify the background color to be 
  shown when hovering over checkbox */ 
  .checkbox-lable:hover input ~ .geekmark { 
    background: white;
    border-color: white
  } 
  
  /* Specify the background color to be 
  shown when checkbox is active */ 
  .checkbox-lable input:active ~ .geekmark { 
    background-color:#2e7d32; 
  } 
  
  /* Specify the background color to be 
  shown when checkbox is checked */ 
  .checkbox-lable input:checked ~ .geekmark { 
    background-color: #2e7d32; 
  } 
  
  /* Checkmark to be shown in checkbox */ 
  /* It is not be shown when not checked */ 
  .geekmark:after { 
    content: ""; 
    position: absolute; 
    display: none; 
  } 
  
  /* Display checkmark when checked */ 
  .checkbox-lable input:checked ~ .geekmark:after { 
    display: block; 
  } 
  
  /* Styling the checkmark using webkit */ 
  /* Rotated the rectangle by 45 degree and  
  showing only two border to make it look 
  like a tickmark */ 
  .checkbox-lable .geekmark:after { 
    left: 8px; 
    bottom: 5px; 
    width: 6px; 
    height: 12px; 
    border: solid white; 
    border-width: 0 4px 4px 0; 
    -webkit-transform: rotate(45deg); 
    -ms-transform: rotate(45deg); 
    transform: rotate(45deg); 
  } 
  .checkbox-input
 { 
  visibility: hidden !important; 
} 
.login-label{
    font-size: 0.8rem !important;
}
.invalid_input{
  border: 1px solid rgb(147 0 0) !important;
}
.invalid_input::placeholder {
  color: rgb(147 0 0) ;
  opacity: 1; /* Firefox */
}
.invalid-select-placeholder{
  color: rgb(147 0 0);

}
.special-label{
  display: none;
}
/* .passenger-header-sticky{
   position: sticky ; 
   top: 80px ;
   z-index: 100;
} */
.form-sub-label {
  font-size: 0.75em;
  display: block;
  word-break: break-word;
 color: undefined;
}
@media (min-width: 1200px) and (max-width : 1311px) { 
  .all-passangers-container{
    margin-left: 49px !important;
    justify-content: normal !important;
    width: 96% !important;
  }
 }
