button.header-link-lang{
    font-size: 15px !important;
}
.header-link.nav-link {
font-size: 18px;
font-weight: 500;
font-family: Roboto, sans-serif;
color: transparent;
text-shadow: 0 0 0 #000;
white-space: nowrap;
background: white;
border: none;
}
.header-link.nav-link:hover,.lang-icon:hover
{
color: #2E7D32 !important;
}
.login-link
{
background-color:#fff !important;
border: 1px solid #2E7D32 !important;
color: transparent;
text-shadow: 0 0 0 #2E7D32 !important;
font-size: 16px !important;
font-weight: 500;
font-family: Roboto, sans-serif;
}
.login-link:hover
{
background-color:#2E7D32 !important;
color: #fff !important;
text-shadow: 0 0 0 #fff !important;
}
.header-link.dropdown-link
{
font-size: 20px;
font-weight: 500;
}
.drop-down-header-links
{
position: absolute;
z-index: 1000;
top: 4rem;
}
.menu-icon-sm
{
position: relative;
top: 10px;
font-size: 30px;
color: #fff;
text-shadow: 0 0 0 #7f7f7f !important;
display: none;
}
.header-link.login-link,.header-menu-sm-container
{
display: inline-block;
}
.slide-menu-mobile
{

   width: 90vw;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 78px;
  left: 0;
  transition: transform .3s
              cubic-bezier(0, .52, 0, 1);
  overflow: scroll;
  z-index: 1001;

}
.slide-menu-mobile.hide{
    transform: translate3d(-100vw, 0, 0);
    transition: transform .3s
    cubic-bezier(0, .52, 0, 1);
}
.slide-menu-mobile.show{
    transform: translate3d(0vw, 0, 0);
    transition: transform .3s
    cubic-bezier(0, .52, 0, 1);
    overflow: hidden;
}
.slide-menu-list
{
    border-radius: 0;
}
.slide-menu-list-item{
    padding: 15px !important;
    font-size: 16px;
    font-weight: 600;
    font-family: Roboto, sans-serif;
    color:transparent;
text-shadow: 0 0 0 #000 !important;
}
.lang-selector{
    position: absolute;
    z-index: 1000;
    top: 4rem;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.07)
}
.user-name-login-box{
    border: 2px solid #1c7430;
    border-radius: 50%;
    box-sizing: border-box;
    text-align:center !important;
    color:white;
    width: 4em;
    height: 4em;
}
.signed-in-user{
    position: absolute;
    right: 2%;
    top: 17%;
}
@media(min-width:1158px)
{
.header-link.dropdown-link
{
    display: none !important;
}
}
@media(max-width:1158px)
{
.manage-trip-link,.help-link
{
    display: none !important;
}
.drop-down-header-links.sm
{
    display: none;
}
}
@media(max-width:900px)
{
.manage-trip-link,.help-link
{
    display: none !important;
}
.check-in-link
{
    display: none !important;
}
.drop-down-header-links.md
{
    display: none !important;
}
.drop-down-header-links.sm
{
    display: block !important;
}
}
@media(max-width:768px)
{
.header-links-md,.header-link.login-link
{
    display: none !important;
}
.header-link.login-link.nav-link {
    position: absolute;
    right: 0;
    top: 35px;
    text-align: center !important;
    font-size: 14px !important;
    padding: 5px 10px !important;
    background: #2e7d32 !important;
    color: white !important;
}
.menu-icon-sm
{
    display: inline-block;
}
.logo-image
{
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
}
.header-nav
{
    padding: 39px 20px !important;
   
}
.header-menu-sm-container
{
    background-color: #2e7d32;
    position: absolute;
    top: 0;
    padding: 31px 22px;
    left: 0;
    cursor: pointer;
}
.lang-selector{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    background: #fff;
}
.lang-selector.hide{
display: none;
}
}
.list-group-item-custom{
    border: none !important;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
}
.list-group-item-custom > a{
font-size: 15px !important;
padding-left: 30px !important;
padding-right: 30px !important;
}