.add_flight_btn{
    float: left !important;
}
.remove_flight_btn {
    float: right;
}
.flight_index{
    
    color: #000 !important;
    float: left !important;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding: 0!important;
}