
body .card-double-range {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

body .card-double-range h2 {
	margin-bottom: 40px;
}

body .card-double-range .current-value {
	width: 100%;
}

body .card-double-range .current-value label {
	display: inline-flex;
	width: 50px;
	font-size: 20px;
}

body .card-double-range .current-value input {
	margin: 0;
	max-width: 70px;
	margin-bottom: 5px;
	font-size: 16px;
	color: white;
	padding: 5px;
	padding-left: 15px;
	border: none;
	border-radius: 20px;
	background: #2e7d32;
}

body .card-double-range .values {
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	margin-top: 30px;
	margin-bottom: 10px;
	width: 240px;
}

body .card-double-range #slider {
	position: relative;
	margin: 0 auto;
	width: 100%;
	height: 8px;
	background: #ddd;
	border-radius: 5px;
	cursor: pointer;
}

body .card-double-range #slider #min {
	position: absolute;
	top: 0;
	z-index: 2;
	width: 0;
	height: 8px;
	border-radius: 5px;
	background: #ddd;
}

body .card-double-range #slider #min #min-drag {
	position: absolute;
    right: -20px;
    top: -9px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ddd;
    box-shadow: 0px 2px 4px 0px #00000059;
    border: 1px solid #0000002b;
}

body .card-double-range #slider #min #min-drag:hover {
	box-shadow: 0 0 0 4px #2e7d3226;
}

body .card-double-range #slider #max {
	position: absolute;
	top: 0;
	z-index: 1;
	width: 0;
	height: 8px;
	border-radius: 5px;
	background: #2e7d32;
}

body .card-double-range #slider #max #max-drag {
	position: absolute;
	right: -7.5px;
	top: -9px;
    width: 25px;
    height: 25px;
	border-radius: 50%;
	background: #ddd;
    /* transition: all .3s; */
    box-shadow: 0px 2px 4px 0px #00000059;
    border: 1px solid #0000002b;
}

body .card-double-range #slider #max #max-drag:hover {
	box-shadow: 0 0 0 4px #2e7d3226;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
body .card-double-range #slider #max[disabled]{
	background: #ddd;
}