.DayPickerNavigation_nextButton__verticalDefault {
	position: fixed;
}

.DateInput_input__focused {
	border: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
	background: #2e7d32;
	border: 1px double #2e7d32;
	color: #fff;
}

.SingleDatePicker {
	width: 100%;
}

.SingleDatePickerInput__withBorder {
	border-radius: 4px;
	width: 100%;
	height: 55px;
}

.SingleDatePickerInput_calendarIcon {
	float: left;
	z-index: 1;
	position: absolute;
	margin-top: 5px;
}

.SingleDatePickerInput__withBorder:hover {
	border: 1px double #2e7d32;
}

.DateInput_input {
	padding-left: 50px;
	padding-right: 50px;
	font-size: 16px;
	margin-top: 4px;
	font-size: 1rem;
	font-weight: 400;
}

.DateInput {
	width: 99%;
	margin-left: 2px;
}

.DateInput_input:focus {
	outline: none;
}

.DateInput_fang {
	z-index: 3;
}

.SingleDatePicker_picker {
	z-index: 2;
}

.SingleDatePickerInput_clearDate {
	padding: 8px 10px 10px;
}

.invalid-date-picker input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: rgb(147 0 0);
	opacity: 1; /* Firefox */
}

.invalid-date-picker input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: rgb(147 0 0);
}

.invalid-date-picker input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: rgb(147 0 0);
}

.invalid-date-picker .SingleDatePickerInput_calendarIcon_svg {
	fill: rgb(147 0 0);
}

.invalid-date-picker .SingleDatePickerInput {
	border: 1px double rgb(147 0 0);
}

.DayPicker_weekHeader_ul {
	display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox; /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
	display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.DayPicker_weekHeader_li {
	display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox; /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
	display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
	flex-grow: 1;
	-webkit-flex-grow: 1;
	justify-content: center;
	-webkit-justify-content: center;
}

.DayPicker_transitionContainer__horizontal {
	width: 100% !important;
}

.CalendarMonthGrid__horizontal {
	left: 0;
}

.DayPicker_weekHeaders__horizontal {
	margin-left: 0;
}

.DayPicker_weekHeader,
.DayPicker_weekHeaders,
.DayPicker,
.DayPicker > div > div,
.SingleDatePicker_picker,
.DayPicker_weekHeaders__horizontal,
.CalendarMonthGrid,
.CalendarMonth_table,
.CalendarMonthGrid_month__horizontal,
.CalendarMonthGrid_month__hidden,
.CalendarMonth {
	width: 100% !important;
	border-radius: 4px;
}

.DayPicker_weekHeader__vertical {
	margin-left: 0px !important;
	left: 0% !important;
}
