.search-flight-result{
    background-color: white !important;
    border-radius: 5px;
    padding: 1em 0em 0.5em 0em !important;
}
.flight-status-result-header{
    background-color: white !important;
    padding: .5em 0em !important;
    z-index: 2;
    border-radius: 5px;
}
.flight-number{
    font-weight: bolder;
    color: #2e7d32;
}
.flight-result-status{
    background-color: #2e7d32;
    color: white;
    padding: .421em !important;
    border-radius: 1.5em;
    text-align: start;
    padding-right: 0.65em !important;
}
.flight-status-ribbon{
    min-width: 33.3%;
    background: white;
    border-radius: 6px;
    height: 3.5em;
    cursor: pointer;
    border-bottom: 3px solid #ffffff;
    user-select: none;
}
.flight-status-ribbon:first-of-type{
    min-width: 31.5%;
    margin-right: 4px;
}
.flight-status-ribbon:last-of-type{
    min-width: 32.3%;
    margin-left: 4px;
}
.flight-status-ribbon.active-ribbon{
    border-bottom: 3px solid #2e7d32;
}
.flight-status-ribbon-date{
    font-size: 14px;
}
.flight-status-DELAYED{
    background-color: #c5a31d;
}
.flight-status-CANCELLED  {
    background-color: #D01110;
}
@media (min-width: 576px) { 
    .flight-status-ribbon{
        min-width: 8em;
        background: white;
        margin: .5em;
        border-radius: 6px;
    }
    .flight-status-ribbon:first-of-type{
        min-width: 8em;
        margin-right: .5em;
    }
    .flight-status-ribbon:last-of-type{
        min-width: 8em;
        margin-left: .5em;
    }
 }
