.schedule-date-button{
    height: 100%;
    cursor: pointer;
}
.schedule-today{
    background-color: #eaf2f7;
}
.schedule-search-result-row{
    background-color: white !important;
    border-radius: 5px;
}
.schedule-date-column{
    -ms-flex: 0 0 9.51%;
    flex: 0 0 9.51%;
    max-width: 9.51%;
}
.schedule-date-column-select{
    -ms-flex: 0 0 14.28%;
    flex: 0 0 14.28%;
    max-width: 14.28%;
}
.schedule-date-column{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.schedule-select-column{
    height: 7.5em;
}
.schedule-date-column-mobile{
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    height: 2.5em;
}
.flight-schedule-header-container{
    background-color: white;
    border-radius: 5px;
    padding: 1em 0;
}
.edit-schedule-wrapper{
    background-color: white;
    border-radius: 0px 0px 5px 5px;
    padding: 1em;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 5px 3px #636f92;
    border-right: none;
}
.edit-schedule-insider{
    border-top: 1px solid #1b1b1b2e;
    margin-top: 1em;
}
.flight-schedule-title-info{
    margin-bottom: 1em;
}
.title-wrapper{
    margin: 2em 0;
}
.edit-flight-day-of-the-week{
    font-weight: 600;
    font-size: 17px;
}
.edit-flight-date{
    font-size: 20px;
}
.internatl-filter-option-container.customized{
    border-bottom: none !important
}