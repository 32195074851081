*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

.plane {
  margin: 20px auto;
  max-width: 300px;
}

.cockpit {
  height: 250px;
  position: relative;
  overflow: hidden;
  text-align: center;
  border-bottom: 5px solid #d8d8d8;
}

.cockpit:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  width: 100%;
  border-radius: 50%;
  border-right: 5px solid #d8d8d8;
  border-left: 5px solid #d8d8d8;
}

.cockpit h1 {
  width: 60%;
  margin: 100px auto 35px auto;
}

.exit {
  position: relative;
  height: 50px;
}

.exit:before,
.exit:after {
  content: "EXIT";
  font-size: 14px;
  line-height: 18px;
  padding: 0px 2px;
  font-family: "Arial Narrow", Arial, sans-serif;
  display: block;
  position: absolute;
  background: green;
  color: white;
  top: 50%;
  transform: translate(0, -50%);
}

.exit:before {
  left: 0;
}

.exit:after {
  right: 0;
}

.fuselage {
  border-right: 5px solid #d8d8d8;
  border-left: 5px solid #d8d8d8;
}

ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .seats {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
}

.seat {
	display: flex;
	flex: 0 0 14.28571428571429%; 
	padding: 5px;
	position: relative;
}

.seat:nth-child(3) {
	/* margin-right: 14.28571428571429%; 
}

.seat input[type=checkbox] {
	position: absolute;
	opacity: 0;
}

.seat input[type=checkbox]:checked + label {
	background: #bada55;
	-webkit-animation-name: rubberBand;
	animation-name: rubberBand;
	animation-duration: 300ms;
	animation-fill-mode: both;
}

.seat input[type=checkbox]:disabled + label {
	background: #dddddd;
	text-indent: -9999px;
	overflow: hidden;
}

.seat input[type=checkbox]:disabled + label:after {
	content: "X";
	text-indent: 0;
	position: absolute;
	top: 4px;
	left: 50%;
	transform: translate(-50%, 0%);
}

.seat input[type=checkbox]:disabled + label:hover {
	box-shadow: none;
	cursor: not-allowed;
}

.seat label {
	display: block;
	position: relative;
	width: 100%;
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.5rem;
	padding: 4px 0;
	background: #F42536;
	border-radius: 5px;
	animation-duration: 300ms;
	animation-fill-mode: both;
}

.seat label:before {
	content: "";
	position: absolute;
	width: 75%;
	height: 75%;
	top: 1px;
	left: 50%;
	transform: translate(-50%, 0%);
	background: rgba(255, 255, 255, 0.4);
	border-radius: 3px;
}

.seat label:hover {
	cursor: pointer;
	box-shadow: 0 0 0px 2px #5C6AFF;
} */

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}
.AISLE {
  max-width: 1em;
}
.seat {
  border: 2px solid #5a5a5a;
  border-radius: 13px 13px 0 0;
  height: 3em;
  min-width: 3em;
  cursor: pointer;
}
.seat-frame {
  border: 2px solid #5a5a5a;
  height: 2.6em;
  min-width: 3.5em;
  border-top: none;
  position: absolute;
  top: 10px;
  cursor: pointer;
}
.seat-container {
  position: absolute;
  width: 62%;
}
.seat-top-wrapper {
  height: 4em;
  max-width: 3.9em;
}
.BULKHEAD {
  height: 0;
  background-color: white;
  max-width: 3.9em;
}
.seat:focus {
  border-width: 2px;
  box-shadow: 0 5px 11px 0px #5a5a5a;
}
.seat-insider-wrapper.active::after {
  content: " ";
  min-width: 2.6em;
  height: 3.1em;
  border-radius: 13px 13px 0 0;
  border-bottom: 1px solid #5a5a5a;
  box-shadow: 0px 9px 9px 0px #5a5a5aab;
}
.column-names {
  font-weight: 500;
  max-width: 3.9em;
  height: 3em;
}
.custom-modal {
  display: none;
  position: absolute;
  border: 1px solid #5a5a5aab;
  max-height: 17em;
  z-index: 28;
  margin-left: 2px;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 4px 10px 0px #5a5a5a91;
  width: 100%;
  left: 0;
  margin: 0;
}
@media (max-width: 767px) {
  .custom-modal {
    position: fixed;
    bottom: 0;
    left: 0 !important;
    width: 100% !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    min-height: 15em;
    box-shadow: 0px -2px 14px 0px #5a5a5a61;
    border: 1px solid #5a5a5a52;
  }
  .seat-pointer {
    display: none;
  }

  .select-seat-btn{
    width: 100%;
  }
}
@media (min-width: 768px) {
  .custom-modal {
    position: absolute;
    top: 42px;
  }
}

.custom-modal.show {
  display: flex;
}
.custom-modal-header {
  width: 100%;
}
.custom-modal-body {
  width: 100%;
}
/* #custom-seat-modal-id::before {
    content: " ";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    z-index: 1;
    margin-top: -8px;
    position: absolute;
    left: 47%;
}
#custom-seat-modal-id::after {
    content: " ";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #5d5d5d;
    margin-top: -9px;
    position: absolute;
    left: 47%;
} */

.seat-pointer {
  height: 1rem;
  width: 1rem;
  position: absolute;
  background: #fff;
  box-shadow: -1px -1px 1px 0px #5a5a5a24;
  top: 34px;
  transform: rotate(45deg);
  z-index: 29;
  border-left: 1px solid #5a5a5a;
  border-top: 1px solid #5a5a5a;
  left: 28px;
}
.close-custom-modal {
  padding-top: 0.4em !important;
}
.seat[disabled] {
  font-size: 1.7em;
  height: 1.76em;
  min-width: 1.76em;
  background-color: #d6d6d6;
  color: #444444;
}
.exit-indicator {
  height: 12px;
  width: 3px;
  background-color: #444444;
  background-color: green;
  position: relative;
  position: absolute;
  /* margin: -3px; */
  bottom: 0em;
}
.second-row {
  top: 0;
}
.select-seat-btn {
  border-radius: 3px;
  font-size: 1rem !important;
  font-weight: 500 !important;
  min-height: 35px !important;
  font-family: Roboto, sans-serif;
  background-color: #fff !important;
  color: #2e7d32 !important;
  border: 1px solid #2e7d32 !important;
  cursor: pointer;
}
.select-seat-btn:hover, .btn-primary:focus, .select-seat-btn:active, .select-seat-btn:active:focus:not(:disabled):not(.disabled) {
  box-shadow: none !important;
  outline: 0;
  background-color: #2e7d32 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.seat-selection-popup-modal-body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: auto; /* Add vertical scrollbar */
  max-height: 9em;
  /* box-shadow: inset 0 0.91667rem 0.66667rem -0.83333rem #ccc, inset 0 -0.91667rem 0.66667rem -0.83333rem #ccc; */
}
/* width */
.seat-selection-popup-modal-body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.seat-selection-popup-modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.seat-selection-popup-modal-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.seat-selection-popup-modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
div.seat-column-9 .seat-row {
  min-width: 36em;
}
div.seat-column-9 .seat-row > div {
  padding: 0 2.3em;
}

/* div.extra-leg-seat-insider-wrapper:first-of-type > div.seat-top-wrapper::before{
  background: red;
}
div.extra-leg-seat-insider-wrapper:last-of-type::before{
  background: red;
} */
.exit-seat {
  padding: 1em 0 0 0 !important;
}
div.seat-column-10 .seat-row {
  min-width: 32em;
}
div.seat-column-13 .seat-row {
  min-width: 41em;
}
.seat-column-7 .seat-row {
  min-width: 33em;
}
.seat-column-7 .seat-row > div {
  padding: 0 3em;
}

div.seat-column-10 .seat-row > div {
  padding: 0 2.3em;
}

.right-or-left-side {
  background: #5a5a5a38;
  padding: 0 1px !important;
  margin: 0 0.5em;
}

.right-or-left-side.LEFT_SIDE {
  box-shadow: -3px 0px 8px #0000009c;
}
.right-or-left-side.RIGHT_SIDE {
  box-shadow: 3px 0px 8px #0000009c;
}

.seats-container-list {
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 8em;
}
.selected-seat {
  padding: 0.4em 0.56em;
  background-color: green;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}
.single-passange.active {
  outline: none;
  box-shadow: 0 0 0 2px #2e7d326b;
  border-radius: 6px;
}
.single-passange:focus {
  outline: none;
}
.extra-leg-seat::before {
  content: "";
  border: 2px solid #5a5a5a;
  border-radius: 13px 13px 0 0;
  cursor: pointer;
  position: absolute;
  min-width: 2em;
  top: -10px;
  height: 0.79em;
  border-color: #5a5a5ae0;
  border-bottom: none;
}
.seat[disabled].extra-leg-seat::before {
  min-width: 1.2em;
  height: 0.47em;
  background-color: #d6d6d6;
  border-bottom: 2px solid #5a5a5a;
}
div.seat-top-wrapper.extra-leg-seat-insider-wrapper {
  margin-top: 2.5em;
}
.premium-seat .seat-frame,
.premium-seat .seat,
.premium-seat .extra-leg-seat::before {
  border-color: blue;
}
.premium-seat .seat,
.premium-seat .extra-leg-seat::before {
  background: #0000ff5c;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #00000012;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #2e7d32;

  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #f9c922;

  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
#log-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #00000012;
}
#log {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  z-index: 12;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
}
#log:before {
  content: "";
  position: absolute;
  top: 37%;
  left: 36%;
  right: 5px;
  bottom: 5px;
  background-image: url(https://www.ethiopianairlines.com/favicon.ico);
  background-repeat: no-repeat;
}

#loader:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #e74c3c;

  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
