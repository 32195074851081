.edit-flight-box{
    max-width: 100%;
    margin: 0 auto;
    height: auto;
    padding: 2rem;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
    z-index: 1000;
    border: 1px solid;
    padding: 10px;
    top: 70px;
}

@media  (min-width:1037px) {
    .edit-flight-box{
        position: -webkit-sticky;
        position: sticky;
    }

}
/* .edit-flight-button{
} */