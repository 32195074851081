.check-in-box {
  background-color: #d5dae6 !important;
  padding: 2em;
  padding-bottom: 4em;
  border-radius: 5px;
}
.disabled-input-check-in input {
  background-color: #eee;
  cursor: not-allowed;
  border: 1px solid #a4adc6 !important;
}
.disabled-input-check-in input:hover {
  border: 1px solid #a4adc6 !important;
}
.check-in-itinerary-list-container {
  background-color: white;
  border-radius: 5px;
  padding: 0px
}
.check-in-itinerary-title-container {
  border-bottom: 1px solid #ddddddd6;
}
.check-in-itinerary-title {
  font-size: 20px;
}
.hazard-icons,
.flammable_liquids,
.magnetised_materials,
.infectious_substances,
.explosives,
.radioactive_materials,
.corrosives,
.toxic_substances,
.gases,
.flammable_solids,
.organic_peroxides {
  background-image: url(https://dx.ci01.cert.aws.sabre.com/dx/ETCI/2.6.19.5725-40.ETCI-2.6.19.5725/images/sabre-dx-component-check-in-hazmat/hazmat.png);
  /* height: 10rem;
    width: 10rem; */
  background-repeat: no-repeat;
}
.hazard-icon-container {
  height: 8rem;
  width: 8rem;
  border: 0.071rem solid #cccccc;
  margin: 0.5rem;
  border-radius: 5px;
}
.flammable_liquids {
  background-position: -36px -16px;
  width: 52px;
  height: 68px;
}
.magnetised_materials {
  background-position: -50px -166px;
  width: 40px;
  height: 47px;
}
.infectious_substances {
  background-position: -52px -278px;
  width: 42px;
  height: 63px;
}
.explosives {
  background-position: -59px -407px;
  width: 15px;
  height: 60px;
}
.radioactive_materials {
  background-position: -42px -530px;
  width: 52px;
  height: 51px;
}
.corrosives {
  background-position: -42px -665px;
  width: 55px;
  height: 42px;
}
.toxic_substances {
  background-position: -52px -779px;
  width: 36px;
  height: 67px;
}
.gases {
  background-position: -48px -909px;
  width: 42px;
  height: 61px;
}
.flammable_solids {
  background-position: -55px -1038px;
  width: 21px;
  height: 60px;
}
.organic_peroxides {
  background-position: -53px -1159px;
  width: 26px;
  height: 67px;
}
button.bk-submit-btn.btn-ethiopian:disabled {
  cursor: not-allowed;
}
button.bk-submit-btn.btn-ethiopian:hover {
  background-color: #2e7d32 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  
}
#pnr.invalid_input {
  margin-bottom: 0px !important;
}
.iternary-parts-separator {
  border-top: 1px solid rgb(0 0 0 / 43%);
  margin-bottom: 0;
}
.iternary-parts-separator-first {
  border-top: 1px solid rgb(0 0 0 / 0%);
  margin: 0;
}
.check-in-departure-time,
.check-in-arrival-time,
.check-in-duration,
.boarding,
.check-in-terminal-gate {
  font-size: 18px;
  font-weight: 600;
}
.check-in-departure-code,
.check-in-arrival-code,
.check-in-departure-date,
.check-in-arrival-date,
.check-in-duration-label,
.check-in-boarding-label,
.check-in-terminal-gate-label,
.check-in-flight-name {
  font-size: 14px;
}
.check-in-flight-equipment {
  font-size: 13px;
}
.check-in-flight-status-indicator {
  padding: 0.3em 0.6em;
  border-radius: 10px;
  background-color: green;
  color: white;
  font-size: 12px;
  font-weight: 600;
}
.check-in-flight-status-indicator-in-shopping-cart {
  padding: 0.3em 0.6em;
  border-radius: 10px;
  background-color: green;
  color: white;
  font-size: 12px;
  font-weight: 600;
}
.check-in-flight-brand {
  font-size: 14px;
}
.check-in-departure-name,
.check-in-arrival-name {
  font-size: 21px;
  font-weight: 400;
}
.check-in-right-arrow {
  font-size: 20px;
}
.bread-crumb-check-in-label {
  font-size: 15px;
}
.bread-crumb-check-in-value {
  font-size: 12px;
}
.breadcrumb-custom {
  margin-bottom: 0px;
}
.breadcrumb-custom li > span {
  color: #666;
  text-decoration: none;
  padding: 10px 0 10px 65px;
  position: relative;
  display: block;
  float: left;
  max-height: 65px !important;
}
.danger-alert-text {
  color: #930000 !important;
}
.check-in-action-container {
  background-color: white;
  border-radius: 5px;
}
.check-in-pnr-value {
  font-weight: 600;
}
.check-in-confirm-passenger-container.check-in-confirm-passenger-container-active {
  box-shadow: 0px 5px 4px 0px #afafafbd;
  border-bottom: 1px solid #b5b5b5;
}
.checkin-confirm-passenger-detail-container.checkin-confirm-passenger-detail-container-active {
  margin-bottom: 1em;
  box-shadow: 0px 5px 4px 0px #afafaf4a;
}
.check-in-confirm-boarding-pass {
  font-weight: 600;
}
.check-in-confirm-passenger-container {
  cursor: pointer;
}
.fa-angle-customized {
  font-size: 25px;
}
.check-in-trip-options-container button {
  font-size: 14px !important;
  font-weight: 700 !important;
}
div.check-in-boarding-pass-modal div.modal-content {
  border-radius: 14px;
}
.boarding-pass-close-btn{
    position: absolute;
    color: white;
    background: #2e7c31;
    width: 2em;
    height: 2em;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -41px;
    margin-left: 6px;
    right: 3px;
    border: 2px solid;
    font-size: 19px;
}
.boarding-pass-icon{
  font-size: 21px;
  padding: 0 7px;
  cursor: pointer;
  color: #00012d;
}
.std-rad{
    border-radius: 5px;
}
.check-in-action-left-container{
  border-bottom: 1px solid #a7a7a7;
}
.progress-simpler-check-in-confirmation > div.lds-ellipsis{
  height: 25px;
}
.progress-simpler-check-in-confirmation > div.lds-ellipsis >  div{
  top: 10px;
}
.container-with-shopping-cart{
  width: 96%;
  height: fit-content;
}
.left-content-container{
  height: fit-content;
}
.left-content-container ,.right-shopping-cart-container{
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.wrapper-with-shopping-cart{
  flex-direction: column-reverse!important;
}
@media (min-width: 576px) {
    div.check-in-boarding-pass-modal > div.modal-sm {
    max-width: 390px;
  }
}
@media (min-width: 768px) {
  .check-in-departure-name,
  .check-in-arrival-name {
    font-size: 26px;
  }
  .check-in-departure-time, .check-in-arrival-time, .check-in-duration, .boarding, .check-in-terminal-gate{
    font-size: 20px;
  }
  .check-in-flight-brand{
    font-size: 16px;
  }
  .check-in-flight-status-indicator{
    font-size: 14px;
  }
  .check-in-flight-status-indicator-in-shopping-cart{
    font-size: 11px;
  }
 .check-in-action-left-container{
    border-right: 1px solid #a7a7a7;
    border-bottom: none;
    text-align: center;
  }
  .container-with-shopping-cart{
    width: 87%;
  }
  .left-content-container, .right-shopping-cart-container{
    -ms-flex: 0 0 94%;
    flex: 0 0 94%;
    max-width: 94%;
  }
}
span.shopping-cart-for-checkin{
  top: -79px;
}
@media (min-width: 1038px){
  .left-content-container{
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-left: 2em;
  }
  .right-shopping-cart-container{
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .wrapper-with-shopping-cart{
    flex-direction: row!important;
  }
  .left-content-container, .right-shopping-cart-container{
    margin-top: 2.5em;
  }
}
@media (min-width: 1200px){
  .container-with-shopping-cart{
    width: 100%;
  }
  .right-shopping-cart-container{
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 1742px){
  .wrapper-with-shopping-cart{
    width: 89% !important;
    margin: 1.5em auto 0 auto !important;
  }
  .right-shopping-cart-container{
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
