.create-account {
  background-color: #d5dae6;
}
.login-page {
  background-color: #d5dae6;
  padding: 20px;
  position: absolute;
  top: 10;
  right: 0;
  z-index: 100;
  max-width: 410px !important;
  box-shadow: 0 13px 26px rgb(0 0 0 / 19%);
  border-bottom: 1px solid #838da7;
  border-radius: 0px 0px 6px 6px;
}
.login-list {
  list-style: none;
  padding: 0 !important;
}
.login-links {
  color: #000;
  background-color: transparent !important;
}
.login-links-active {
  color: #008bdb !important;
}
span.account-step {
  background: #2e7d32;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  text-align: center;
  width: 1.6em;
}

.et-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.et-checkbox label {
  position: relative;
  cursor: pointer;
}

.et-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #2e7d32;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  background-color: white;
}

.et-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #000;
  font-weight: bolder;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.manage-profile-box {
  background-color: #d5dae6 !important;
  padding: 2rem;
}

.manage-profile-tab {
  font-family: Lato, serif;
  font-size: 1rem;
  font-weight: 500;
}
.user-name-box {
  border: 2px solid #1c7430;
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center !important;
  color: white;
  height: 5em;
  width: 5em;
}
.user-name-text {
  color: #1c7430;
  font-weight: bolder;
}
.user-another-card {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  cursor: pointer !important;
}
.disabled-input {
  cursor: not-allowed;
  background-color: #eee !important;
  color: black;
}
@media screen and (max-width: 768px) {
  .credit-card-list {
    background-color: #a4adc6;
  }
}

.disabled-input:hover {
  border-color: transparent !important;
}
.remove-frequent-flyer {
  background-color: #dc3545;
}
.frequent-flyer-btn {
  width: 32px !important;
  height: 32px !important;
}
.remove-frequent-flyer:hover {
  background-color: #dc3545 !important;
}
.remove-frequent-flyer:focus {
  background-color: #dc3545 !important;
}
.trip-box {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 3px ​2px whitesmoke;
}
@media (max-width: 768px) {
  .manage-profile-box {
    background-color: #d5dae6 !important;
    padding: 1rem;
  }
  .user-name-box {
    padding: 1rem !important;
  }
  .frequent-flyer-h3 {
    font-size: 1.3rem !important;
  }
  .manage-profile-tab {
    font-size: 10px;
    margin: 0;
    padding: 0;
  }
  .manage-profile-container {
    min-width: 100% !important;
  }
}
.invalid_input .DateInput_input::placeholder {
  color: rgb(147 0 0);
}
