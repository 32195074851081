.recent-search-btn
{
    font-size: 1rem !important;
    font-weight: 500 !important;
    min-height: 45px !important;
    background: transparent !important;
}
.recent-search-btn:focus
{
    outline: none !important;
}
.recent-search-caret
{
    position: absolute;
    top: 10px;
    font-size: 25px;
    color: #767676;
    cursor: pointer;
}
.circle-span
{
    height: 30px;
    border: 1px solid #ddd;
    width: 30px;
    font-size: 16px;
    padding: 4px 10px;
    border-radius: 72px;
    text-align: center;
    color: transparent !important;
    text-shadow: 0 0 0 #9e9e9e91;
}
.recent-search-journey-class
{
    color: #9e9e9e91;
    font-size: 14px;
    padding-left: 2.8rem!important;
}
span.spark-icon-arrows-left-right {
    font-size: 16px;
    padding: 0 14px;
    color: #5a5555;
}
.recent-search-list-item
{
    cursor: pointer;
    color: transparent !important;
    text-shadow: 0 0 0 #000;
    border-radius: 5px !important;
}
.recent-search-city {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 3px;
}
.recent-search-city.more-result
{
    padding-left: 1.8rem!important;
  padding: 10px 0;

}
.recent-search-airport-cnt
{
    text-align: left;
    width: 100%;
    background: hsla(0,0%,100%,.1);
    position: relative;
    cursor: pointer;
}
.recent-flight-list-container{
    border-top: 1px solid #0000003d;
    border-radius: 6px;
}