body .card-single {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

body .card-single h2 {
	margin-bottom: 40px;
}

body .card-single .current-value {
	width: 100%;
}
body .card-single #slider #min-slider {
	position: absolute;
	top: 0;
	z-index: 2;
	width: 0;
	height: 8px;
	border-radius: 5px;
	background: #ddd;
}

body .card-single #slider {
	position: relative;
	margin: 0 auto;
	width: 100%;
	height: 8px;
	background: #ddd;
	border-radius: 5px;
	cursor: pointer;
}

body .card-single #slider #min-slider #min-drag {
	position: absolute;
    right: -7.5px;
    top: -9px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ddd;
    box-shadow: 0px 2px 4px 0px #00000059;
    border: 1px solid #0000002b;
}

body .card-single #slider #min-slider #min-drag:hover {
	box-shadow: 0 0 0 4px #2e7d3226;
}

body .card-single #slider #min-slider {
	position: absolute;
	top: 0;
	z-index: 1;
	width: 0;
	height: 8px;
	border-radius: 5px;
	background: #2e7d32;
}
body .card-single #slider #min-slider[disabled]{
	background: #ddd;
}

