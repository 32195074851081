.disable-alternate-btn, .disabled-ribbon{
    cursor: not-allowed !important;  
}
.disabled-ribbon{
  background-color: #ddd !important;
  
}
.fareoption-container {
    border: 1px solid #ddd;
    min-width: 11.66667rem;
    border-left: none;
    z-index: 0;
    text-align: center;
    cursor: pointer;
    min-height: 5.333rem;
    height: 100%;
    background-color: white;
    border-radius: 10px;
  }
  .col.px-0.fareoption-container:hover {
    background-color: #ffffffc9;
}

  .fareoption-container > div{
  width: 100%;
  }
  .fareoption-container:first-of-type {
      border: 1px solid #ddd;
  }
 
  .fareoption-container-active {
    color: #2e7d32;
    z-index: 9;
    transform: translate3d(0px, 0px, 0px);
    border-bottom: 5px solid #2e7d32 !important;
  }
 
  .fareoption-container-active::after {
    
    height: 4px;
    width: 100%;
    background-color: #2e7d32;
    
    z-index: 1;
    position: absolute;
    left: 0;
    
  }
  .horizontal_flex_container {
    overflow-x: auto;
    overflow-y: hidden;
    transform: translateZ(0);
    -webkit-transition: -webkit-transform cubic-bezier(0.86, 0, 0.07, 1);
    transition: -webkit-transform cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform cubic-bezier(0.86, 0, 0.07, 1);
    transition: transform cubic-bezier(0.86, 0, 0.07, 1),
      -webkit-transform cubic-bezier(0.86, 0, 0.07, 1);
      scroll-snap-type: x mandatory;
  }
  .fareoption-container span.date {
    font-size: 1.333rem;
    font-weight: 300;
    scroll-snap-align: center;
  }
  .fareoption-container span.price {
    font-size: 0.85em;
    font-weight: 600;
  }
  .fareoption-container-active span.price {
    font-size: 0.85em;
    font-weight: 700;
  }
  .fareoption-container-active span.date {
    font-size: 1.333rem;
    font-weight: 300;
  }
  @media (min-width: 992px) {
    .fareoption-container {
      min-width: 14.2%;
    }
  }
  
  .alternate-btn {
    background: none;
    border: none;
    height: 2.5em;
    width: 2.5em;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #ffffffc9;
    border-radius: 50%;
    position: relative;
    z-index: 2;

  }
  button.alternate-btn:hover {
    background-color: white;
    box-shadow: 0px 6px 8px 0px #5f5f5fcc;
}
button.disable-alternate-btn:hover{
  background-color: #ffffffc9;
    box-shadow: 11px 0 7px -9px rgba(0, 0, 0, 0.3);
}
  .alternate-btn:focus {
    outline: none !important;
  }
  
  .back-alternate-shadow {
    -webkit-box-shadow: 11px 0 7px -9px rgba(0, 0, 0, 0.3);
    box-shadow: 11px 0 7px -9px rgba(0, 0, 0, 0.3);
  }
  .next-alternate-shadow {
    -webkit-box-shadow: -11px 0 8px -7px rgba(0, 0, 0, 0.3);
    box-shadow: -11px 0 8px -7px rgba(0, 0, 0, 0.3);
  }
  .back-alternate-btn {
      
      margin-right: -12px;
    }
  .next-alternate-btn {
    
    margin-left: -12px;
  }
.flight-ribbon-top-wrapper{
  display: flex;
  width: 100%;
  margin: 0 7px;
}
.col.px-0.fareoption-container.fareoption-container-active > div {
  padding-bottom: .1em !important;
}
